import { Dictionary } from "vue-router/types/router";

export class AssetRepository {
  public static ASSET_REPOSITORY: Dictionary<AssetTheme> = {
    cockpit: {
      light: require("@/assets/cockpit.svg")
    },
    cockpitExample: {
      light: require("@/assets/instructions/cockpit_blaupause.gif"),
      dark: require("@/assets/instructions/cockpit_blaupause_dark.gif")
    },
    cockpitInstruction: {
      light: require("@/assets/instructions/cockpit.jpeg")
    },
    carFrontLeft: {
      light: require("@/assets/car_front_left.svg")
    },
    carFrontRight: {
      light: require("@/assets/car_front_right.svg")
    },
    carRearLeft: {
      light: require("@/assets/car_rear_left.svg")
    },
    carRearRight: {
      light: require("@/assets/car_rear_right.svg")
    },
    damageDetail: {
      light: require("@/assets/car_damage_detail.svg")
    },
    damageDetailExample: {
      light: require("@/assets/instructions/damage_detail_blaupause.gif"),
      dark: require("@/assets/instructions/damage_detail_blaupause_dark.gif")
    },
    damage: {
      light: require("@/assets/car_damage.svg")
    },
    overview: {
      light: require("@/assets/instructions/overview_blaupause.gif"),
      dark: require("@/assets/instructions/overview_blaupause_dark.gif")
    },
    registration: {
      light: require("@/assets/registration.svg")
    },
    registrationExample: {
      light: require("@/assets/instructions/registration_blaupause.gif"),
      dark: require("@/assets/instructions/registration_blaupause_dark.gif")
    },
    registrationCorrectInstructions: {
      light: require("@/assets/instructions/beispielrichtig.gif")
    },
    registrationIncorrectInstructions: {
      light: require("@/assets/instructions/beispielfalsch.gif")
    },
    damageOverviewBehindInstruction: {
      light: require("@/assets/instructions/overview_behind.png")
    },
    damageOverviewFrontInstruction: {
      light: require("@/assets/instructions/overview_front.png")
    },
    damageDetailInstruction: {
      light: require("@/assets/instructions/damage_detail.png")
    },
    damageInstruction: {
      light: require("@/assets/instructions/damage.png")
    },
    damageExample: {
      light: require("@/assets/instructions/damage_blaupause.gif"),
      dark: require("@/assets/instructions/damage_blaupause_dark.gif")
    }
  };

  public static getAsset(isDarkTheme: boolean, assetType: AssetEnum) {
    if (assetType in this.ASSET_REPOSITORY) {
      const hasDarkTheme = this.ASSET_REPOSITORY[assetType].dark
        ? this.ASSET_REPOSITORY[assetType].dark
        : this.ASSET_REPOSITORY[assetType].light;
      return isDarkTheme ? hasDarkTheme : this.ASSET_REPOSITORY[assetType].light;
    }
  }
}

export enum AssetEnum {
  overview = "overview",
  cockpit = "cockpit",
  cockpitExample = "cockpitExample",
  cockpitInstruction = "cockpitInstruction",
  damageDetail = "damageDetail",
  damageDetailExample = "damageDetailExample",
  damageDetailInstruction = "damageDetailInstruction",
  damage = "damage",
  damageExample = "damageExample",
  damageInstruction = "damageInstruction",
  damageOverviewBehindInstruction = "damageOverviewBehindInstruction",
  damageOverviewFrontInstruction = "damageOverviewFrontInstruction",
  carFrontLeft = "carFrontLeft",
  carFrontRight = "carFrontRight",
  carRearLeft = "carRearLeft",
  carRearRight = "carRearRight",
  registration = "registration",
  registrationExample = "registrationExample",
  registrationCorrectInstructions = "registrationCorrectInstructions",
  registrationIncorrectInstructions = "registrationIncorrectInstructions",
  registrationIncorrectInstructionsPlaceholder = "registrationIncorrectInstructionsPlaceholder",
  registrationCorrectInstructionsPlaceholder = "registrationCorrectInstructionsPlaceholder"
}

interface AssetTheme {
  light: any;
  dark?: any;
}
