
























import { Component, Prop, Vue } from "vue-property-decorator";

import LayoutReport from "@/layouts/LayoutReport.vue";
import ReportHeader from "@/components/report/ReportHeader.vue";
import { AssetEnum, AssetRepository } from "@/lib/AssetRepository";

@Component({
  components: {
    LayoutReport,
    ReportHeader
  }
})
export default class LayoutReportStep extends Vue {
  @Prop({ default: 6 })
  md!: number;

  @Prop({})
  debug!: any;

  @Prop({})
  description!: string;

  @Prop({})
  title!: string;

  @Prop({})
  image!: AssetEnum;

  @Prop({})
  hasInstructions!: boolean;

  @Prop({ default: "Anleitung" })
  instructionTitle!: string;

  @Prop({ default: "Hier ist ein Beispiel wie das Foto am besten aufzunehmen ist." })
  instructionText!: string;

  @Prop()
  instructionPictures!: AssetEnum[];

  @Prop({ default: true })
  isDone!: boolean;

  @Prop({ default: true })
  hasNext!: boolean;

  get imageFromAssetRepository() {
    if (this.image) {
      return AssetRepository.getAsset(this.$vuetify.theme.dark, this.image);
    }
    return null;
  }

  get instructionPicturesImages(): string[] {
    const images: string[] = [];

    if (!this.instructionPictures) {
      return images;
    }

    for (const picture of this.instructionPictures) {
      const asset = AssetRepository.getAsset(this.$vuetify.theme.dark, picture);
      images.push(asset);
    }

    return images;
  }
}
