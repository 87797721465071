











import { Component, Vue, Prop } from "vue-property-decorator";

import ErrorAlert from "@/components/utility/ErrorAlert.vue";
import TopToolbarBack from "@/components/navigation/TopToolbarBack.vue";

@Component({ components: { ErrorAlert, TopToolbarBack } })
export default class LayoutSimple extends Vue {
  @Prop({ default: "" })
  title!: string;
}
