































































import { Component, Ref, Vue } from "vue-property-decorator";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import { requiredRule } from "@/lib/rules/requiredRule";
import { Type } from "@/lib/utility/type";
import { IReport } from "@/models/Report/IReport";
import { ReportModule } from "@/store/modules/report.store";

import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { ConfigModule } from "@/store/modules/config";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportSummary extends Vue implements IReportStep {
  header: IReportHeaderData = {
    title: "Schadensmeldung abschließen",
    description:
      "Bitte überprüfen Sie nun Ihre Eingaben und lesen unsere  <a target='blank()' href='https://www.mmmint.ai/datenschutz/'>Datenschutzerklärung</a>."
  };

  get privacyPolicyLink() {
    return ConfigModule.privacyPolicyLink;
  }

  isLoading = false;

  @Ref("reviewed")
  readonly reviewedElement!: HTMLInputElement;

  get email() {
    return ReportModule.contact.email;
  }

  get isDone() {
    return this.isValid() && this.isReviewed && this.isPrivacyChecked;
  }

  get reportReviewedRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(this.reportAddressValidRule());
    rules.push(this.reportContactValidRule());
    rules.push(this.reportUserValidRule());
    rules.push(this.reportDamageLocationValidRule());
    rules.push(this.reportNumberValidRule());

    return rules;
  }

  reportAddressValidRule(message = "Anschrift Feld muss vollständig gefüllt sein"): boolean | string {
    const b = Type.isPresent(ReportModule.address.street) && !Type.isBlankString(ReportModule.address.street);
    const c = Type.isPresent(ReportModule.address.street) && !Type.isBlankString(ReportModule.address.zip);
    const d = Type.isPresent(ReportModule.address.street) && !Type.isBlankString(ReportModule.address.city);
    return (b && c && d) || message;
  }

  reportContactValidRule(
    message = "Kontakt Daten (Telefon & Email) müssen vollständig gefüllt sein"
  ): boolean | string {
    return (
      (Type.isPresent(ReportModule.contact.phone) &&
        Type.isPresent(ReportModule.contact.email) &&
        !Type.isBlankString(ReportModule.contact.phone) &&
        !Type.isBlankString(ReportModule.contact.email)) ||
      message
    );
  }

  reportUserValidRule(message = "Nutzer Daten (Vorname, Name) müssen vollständig gefüllt sein"): boolean | string {
    return (
      (Type.isPresent(ReportModule.user.firstName) &&
        Type.isPresent(ReportModule.user.lastName) &&
        !Type.isBlankString(ReportModule.user.firstName) &&
        !Type.isBlankString(ReportModule.user.lastName)) ||
      message
    );
  }

  reportDamageLocationValidRule(message = "Schadensbereich muss ausgeählt sein"): boolean | string {
    return ReportModule.inCreationReport.damageLocation.length > 0 || message;
  }

  reportDamageDescriptionValidRule(message = "Schadenbeschreibung mus gefüllt sein"): boolean | string {
    return (
      (Type.isPresent(ReportModule.inCreationReport.message) &&
        !Type.isBlankString(ReportModule.inCreationReport.message)) ||
      message
    );
  }

  reportNumberValidRule(message = "Kennzeichen mus gefüllt sein"): boolean | string {
    return (Type.isPresent(ReportModule.numberplate) && !Type.isBlankString(ReportModule.numberplate)) || message;
  }

  private isValid() {
    return (
      !Type.isBlankString(ReportModule.numberplate) &&
      !Type.isBlankString(ReportModule.user.firstName) &&
      !Type.isBlankString(ReportModule.user.lastName) &&
      !Type.isBlankString(ReportModule.address.street) &&
      !Type.isBlankString(ReportModule.address.zip) &&
      !Type.isBlankString(ReportModule.address.city) &&
      !Type.isBlankString(ReportModule.contact.phone) &&
      !Type.isBlankString(ReportModule.contact.email) &&
      ReportModule.inCreationReport.damageLocation.length >= 0 &&
      this.isReviewed &&
      ReportModule.inCreationReport.isPrivacyChecked
    );
  }

  get isPrivacyChecked(): boolean {
    return ReportModule.inCreationReport.isPrivacyChecked;
  }

  set isPrivacyChecked(bool: boolean) {
    ReportModule.setPrivacyCheck(bool);
  }

  isReviewed = false;

  get isMarketingContactAllowed(): boolean {
    return ReportModule.inCreationReport.isMarketingOptIn;
  }

  set isMarketingContactAllowed(bool: boolean) {
    ReportModule.setMarketingContactAllowed(bool);
  }

  get report(): IReport {
    return ReportModule.inCreationReport;
  }

  async submit() {
    this.isLoading = true;
    try {
      await ReportModule.submit();
      this.$router.push({ name: "ReportSuccess" });
    } catch (error) {
      this.$toast.error("Fehler beim senden.");
    } finally {
      this.isLoading = false;
    }
  }
}
