



















































import { Component, Vue } from "vue-property-decorator";

import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import FileUploadMulti from "@/components/files/FileUploadMulti.vue";

import { AssetEnum } from "@/lib/AssetRepository";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";

import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    LayoutReportStep,
    FileUploadMulti
  }
})
export default class ReportImageOverview extends Vue implements IReportStep {
  multiSteps = {
    frontLeft: {
      example: AssetEnum.carFrontLeft,
      text: "Foto von vorne links",
      textAlt: "Foto von vorne links erneut aufnehmen",
      filesCb: ReportModule.overviewsFrontLeft,
      handleUpload: ReportModule.addOverviewFrontLeft,
      handleDelete: ReportModule.removeOverviewFrontLeft
    },
    rearLeft: {
      example: AssetEnum.carRearLeft,
      text: "Foto von hinten links",
      textAlt: "Foto von vorne links erneut aufnehmen",
      filesCb: ReportModule.overviewsRearLeft,
      handleUpload: ReportModule.addOverviewRearLeft,
      handleDelete: ReportModule.removeOverviewRearLeft
    },
    rearRight: {
      example: AssetEnum.carRearRight,
      text: "Foto von hinten rechts",
      textAlt: "Foto von vorne links erneut aufnehmen",
      filesCb: ReportModule.overviewsRearRight,
      handleUpload: ReportModule.addOverviewRearRight,
      handleDelete: ReportModule.removeOverviewRearRight
    },
    frontRight: {
      example: AssetEnum.carFrontRight,
      text: "Foto von vorne rechts",
      textAlt: "Foto von vorne links erneut aufnehmen",
      filesCb: ReportModule.overviewsFrontRight,
      handleUpload: ReportModule.addOverviewFrontRight,
      handleDelete: ReportModule.removeOverviewFrontRight
    }
  };

  get getOverviewFrontLeft() {
    return ReportModule.overviewsFrontLeft;
  }

  get getOverviewFrontRight() {
    return ReportModule.overviewsFrontRight;
  }

  get getOverviewRearLeft() {
    return ReportModule.overviewsRearLeft;
  }

  get getOverviewRearRight() {
    return ReportModule.overviewsRearRight;
  }

  header: IReportHeaderData = {
    title: "Gesamtansicht",
    description:
      "Bitte fertigen Sie Übersichtsaufnahmen Ihres Fahrzeuges an. Die Schadensfotos folgen im nächsten Schritt.",
    image: AssetEnum.overview
  };

  instruction: IReportInstructionData = {
    title: this.header.title,
    text:
      "Bitte fertigen Sie Übersichtsaufnahmen Ihres Fahrzeuges an. Die nötigen Bereiche sind vorne links, vorne rechts, hinten links & hinten rechts. Stellen Sie sicher dass alle Seiten des Fahrzeuges gut erkennbar sind.",
    pictures: [AssetEnum.damageOverviewFrontInstruction, AssetEnum.damageOverviewBehindInstruction],
    example: AssetEnum.damageInstruction,
    display: true
  };

  /**
   * @inheritdoc
   */
  get isDone(): boolean {
    if (this.getOverviewFrontLeft.length <= 0) {
      return false;
    }

    if (this.getOverviewFrontRight.length <= 0) {
      return false;
    }

    if (this.getOverviewRearLeft.length <= 0) {
      return false;
    }

    if (this.getOverviewRearRight.length <= 0) {
      return false;
    }

    return true;
  }
}
