






























import { Component } from "vue-property-decorator";

import { ReportModule } from "@/store/modules/report.store";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import ImageUploadButton from "@/components/files/ImageUploadButton.vue";
import FileUpload from "@/components/files/FileUpload.vue";
import FileExample from "@/components/files/FileExample.vue";

import { AssetEnum } from "@/lib/AssetRepository";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";

import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IImageUploaded } from "@/models/Image/IImageUploaded";

@Component({
  components: {
    LayoutReportStep,
    ImageUploadButton,
    FileExample,
    FileUpload
  }
})
export default class ReportImageCockpit extends ReportImageSubmission implements IReportStep, IViewImageUploadable {
  public example = AssetEnum.cockpit;

  header: IReportHeaderData = {
    title: "Kilometerstand",
    description:
      "Bitte laden Sie ein Foto des Kilometerstandes bei laufendem Motor hoch. Dieses benötigen wir für die Dokumentation gegenüber der Versicherung.",
    image: AssetEnum.cockpitExample,
    debug: this.files
  };

  instruction: IReportInstructionData = {
    title: "Foto bei laufendem Motor",
    text:
      "Bitte dokumentieren Sie den Kilometerstand bei laufendem Motor, damit wir potenzielle Kontrolleuchten aus Ihrem Cockpit identifizieren können.",
    pictures: [AssetEnum.cockpitInstruction],
    example: AssetEnum.cockpitExample,
    display: true
  };

  maxFiles = 1;

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    await ReportModule.addCockpit(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    await ReportModule.removeCockpit(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.cockpits;
  }
}
