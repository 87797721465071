





















import { Component, Vue } from "vue-property-decorator";

import LayoutReportStep from "@/layouts/LayoutReportStep.vue";

import { ReportModule } from "@/store/modules/report.store";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportDescription extends Vue implements IReportStep {
  public header: IReportHeaderData = {
    title: "Was ist passiert?",
    description:
      "Bitte beschreiben Sie den Schadenshergang in Kurzform. <br/> <br/> Hier können Sie außerdem alle weiteren Informationen hinterlassen, die Sie uns mitteilen möchten."
  };

  counter = 1000;
  ruleText = `Nachricht bitte unter ${this.counter} Zeichen belassen`;
  placeholder = "Schadenshergang";

  internalMessage = "";

  get isDone() {
    return true;
  }

  get message(): string {
    return ReportModule.inCreationReport.message;
  }

  set message(m: string) {
    this.internalMessage = m;
  }

  updateMessage(e: any) {
    ReportModule.setMessage(e.target.value);
  }
}
