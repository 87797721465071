
















import { Component, Vue } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";
import { ErrorLogModule } from "@/store/modules/error-log";

@Component
export default class SettingsList extends Vue {
  set debug(bo: boolean) {
    ConfigModule.setDebug(bo);
  }

  get debug() {
    return ConfigModule.debug;
  }

  set darkMode(bo: boolean) {
    ConfigModule.setDarkMode(bo);
  }

  get darkMode() {
    return ConfigModule.darkMode;
  }

  generateError() {
    ErrorLogModule.addErrorLog({
      name: "name",
      message: "message"
    });
  }
}
