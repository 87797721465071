import Vue from "vue";
import { Api } from "@/services/mrfiktiv/api";
import { AxiosResponse } from "axios";
import { PartnerContactEntity } from "@/models/partnerContactEntity";
import { Utils } from "@/lib/utility/utils";
import { PartnerEntity } from "@/models/partnerEntity";
import { RegistrationResultsEntity } from "@/models/registrationResultsEntity";

class PartnerService {
  ROUTE = "partner";

  async contact(partnerContact: PartnerContactEntity, partnerName: string): Promise<any> {
    const api = new Api().instance();

    let response: AxiosResponse;

    const formData = new FormData();
    Utils.buildFormData(formData, partnerContact);

    const config = {
      headers: {
        "content-type": "multipart/form-data"
      }
    };

    try {
      response = await api.post(`/${this.ROUTE}/${partnerName}/contact`, formData, config);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  async getPartnerByName(partnerName: string): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.get(`/partner/${partnerName}/byName`);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  // TODO: Use those when switching to new generated API proxy.
  //
  // async findAll(partnerId: string = this.partnerId): Promise<ReportEntity[]> {
  //   let reports: ReportDocument[];
  //   try {
  //     const response = await this.client.reportControllerFindAll(partnerId);
  //     reports = response.data;
  //   } catch (error) {
  //     throw error;
  //   }

  //   return reports;
  // }

  // async findOne(reportId: string, partnerId: string = this.partnerId): Promise<ReportEntity> {
  //   let report: ReportDocument;
  //   try {
  //     const response = await this.client.reportControllerFindOne(partnerId, reportId);
  //     report = response.data;
  //   } catch (error) {
  //     throw error;
  //   }

  //   return report;
  // }
  async getPartnerById(partnerId: string): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.get(`/partner/${partnerId}`);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  async getAllPartners(): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.get("/partner");
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  async updatePartner(partner: PartnerEntity): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    // dirty-fix for now, wrap partnerEntity to only the allowed properties
    const partnerData = {
      address: partner.address,
      companyName: partner.companyName,
      contact: partner.contact,
      settings: partner.settings
    };

    try {
      response = await api.patch(`/partner/${partner._id}`, partnerData);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  async deletePartner(partnerId: string): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.delete(`/partner/${partnerId}`);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  // ### FORM ###

  async getAllFormsForPartner(partnerId: string): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.get(`/partner/${partnerId}/contact`);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  async getFormById(partnerId: string, formId: string): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.get(`/partner/${partnerId}/contact/${formId}`);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  async getExportForFormById(partnerId: string, formId: string, exportType: string): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.get(`/partner/${partnerId}/contact/${formId}/export/${exportType}`);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  // ### REPORT ###

  async getAllReportsForPartner(partnerId: string): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.get(`/partner/${partnerId}/report`);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  async getReportById(partnerId: string, reportId: string): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.get(`/partner/${partnerId}/report/${reportId}`);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  async getExportForReportById(partnerId: string, reportId: string, exportType: string): Promise<any> {
    const api = new Api().instance();
    let response: AxiosResponse;

    try {
      response = await api.get(`/partner/${partnerId}/report/${reportId}/export/${exportType}`);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return response;
  }

  async addRegistrationDetailsForForm(
    partnerId: string,
    formId: string,
    registrationResult: RegistrationResultsEntity
  ): Promise<any> {
    const api = new Api().instance();

    try {
      const response = await api.post(`/partner/${partnerId}/contact/${formId}/registration`, registrationResult);
      registrationResult = response.data as RegistrationResultsEntity;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return registrationResult;
  }

  async addRegistrationDetailsForReport(
    partnerId: string,
    reportId: string,
    registrationResult: RegistrationResultsEntity
  ): Promise<any> {
    const api = new Api().instance();

    try {
      const response = await api.post(`/partner/${partnerId}/report/${reportId}/registration`, registrationResult);
      registrationResult = response.data as RegistrationResultsEntity;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return registrationResult;
  }
}

export default new PartnerService();
