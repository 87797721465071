







import Loader from "@/components/utility/Loader.vue";
import { PartnerModule } from "@/store/modules/partner";
import PartnerContact from "@/views/PartnerContact.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    PartnerContact,
    Loader
  }
})
export default class Contact extends Vue {
  get loading() {
    return PartnerModule.loading;
  }
}
