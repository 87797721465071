































import { Component } from "vue-property-decorator";

import { ReportModule } from "@/store/modules/report.store";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import ImageUploadButton from "@/components/files/ImageUploadButton.vue";
import FileUpload from "@/components/files/FileUpload.vue";
import FileExample from "@/components/files/FileExample.vue";

import { AssetEnum } from "@/lib/AssetRepository";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";

import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IImageUploaded } from "@/models/Image/IImageUploaded";

@Component({
  components: {
    LayoutReportStep,
    ImageUploadButton,
    FileExample,
    FileUpload
  }
})
export default class ReportImageDamage extends ReportImageSubmission implements IReportStep, IViewImageUploadable {
  public example = AssetEnum.damage;

  header: IReportHeaderData = {
    title: "Schadensbereich",
    description:
      "Bitte fotografieren Sie die beschädigte Region an Ihrem Fahrzeug. Die Detailaufnahmen folgen im nächsten Schritt.",
    image: AssetEnum.damageExample,
    debug: this.files
  };

  instruction: IReportInstructionData = {
    title: this.header.title,
    text:
      "Bitte fotografieren Sie die Region des Schadens an Ihrem Fahrzeug. Detaillierte Aufnahmen werden im nächsten Schritt erfasst, hier geht es darum zu identifizieren <b>wo</b> sich der Schaden am Fahrzeug befindet.​",
    pictures: [AssetEnum.damageInstruction],
    example: AssetEnum.damageExample,
    display: true
  };

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    await ReportModule.addDamage(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    return await ReportModule.removeDamage(file);
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.damages;
  }
}
