















import { Component, Vue } from "vue-property-decorator";
import LayoutNone from "@/layouts/LayoutNone.vue";

@Component({
  components: {
    LayoutNone
  }
})
export default class PartnerContactError extends Vue {}
