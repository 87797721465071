import Vue from "vue";
import { Api } from "@/services/mrfiktiv/api";
import { UserEntity } from "@/models/userEntity";

class UserService {
  ROUTE = "/user";

  async get(id: string): Promise<UserEntity> {
    const api = new Api().instance();

    let User: UserEntity;

    try {
      const response = await api.get(`${this.ROUTE}/${id}`);
      User = response.data as UserEntity;
    } catch (error) {
      Vue.$log.error(error);

      throw error;
    }

    return User;
  }

  async getByAuthInfo(): Promise<UserEntity> {
    const api = new Api().instance();

    let User: UserEntity;

    try {
      const response = await api.get(`${this.ROUTE}`);
      User = response.data as UserEntity;
    } catch (error) {
      Vue.$log.error(error);

      throw error;
    }

    return User;
  }

  async update(id: string, updateUser: UserEntity): Promise<UserEntity> {
    const api = new Api().instance();

    try {
      const response = await api.put(`${this.ROUTE}/${id}`, updateUser);
      updateUser = response.data as UserEntity;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return updateUser;
  }

  async create(createUser: UserEntity): Promise<UserEntity> {
    const api = new Api().instance();

    try {
      const response = await api.post(`${this.ROUTE}`, createUser);
      createUser = response.data as UserEntity;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return createUser;
  }
}

export default new UserService();
