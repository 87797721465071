




















import { Component, Prop } from "vue-property-decorator";

import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import ImageUploadButton from "@/components/files/ImageUploadButton.vue";
import FileExample from "@/components/files/FileExample.vue";
import FileUpload from "@/components/files/FileUpload.vue";

import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { AssetEnum } from "@/lib/AssetRepository";

@Component({
  components: {
    ImageUploadButton,
    FileExample,
    FileUpload
  }
})
export default class FileUploadMulti extends ReportImageSubmission implements IViewImageUploadable {
  public maxFiles = 1;

  @Prop()
  public example!: AssetEnum;

  @Prop()
  public handleUploadCb!: (file: File) => Promise<void>;

  @Prop()
  public handleDeleteCb!: (file: File) => Promise<boolean>;

  @Prop()
  public filesCb!: IImageUploaded[];

  @Prop()
  public displayImages!: IImageUploaded[];

  @Prop()
  public text?: string;

  @Prop()
  public textAlt?: string;

  get hasFile(): boolean {
    if (!this.displayImages) {
      return false;
    }
    return this.displayImages.length >= 1;
  }

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    if (this.maxFiles !== null && this.filesCb.length >= this.maxFiles) {
      const toDelete = this.filesCb[this.filesCb.length - 1].file;
      await this.handleDeleteCb(toDelete);
    }

    await this.handleUploadCb(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    await this.handleDeleteCb(file);
    return true;
  }

  get files(): IImageUploaded[] {
    if (this.displayImages) {
      return this.displayImages.slice().reverse();
    } else {
      return this.displayImages;
    }
  }
}
