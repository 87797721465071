



















































































import { Component, Vue } from "vue-property-decorator";
import { UserEntity } from "@/models/userEntity";
import { AddressEntity } from "@/models/addressEntity";
import { ContactEntity } from "@/models/contactEntity";
import { UserModule } from "@/store/modules/user";
import LayoutSimple from "@/layouts/LayoutSimple.vue";

@Component({
  components: { LayoutSimple }
})
export default class ProfileCard extends Vue {
  get user(): UserEntity {
    return UserModule.user;
  }

  get contact(): ContactEntity {
    return UserModule.user.contact || ({ email: "", phone: "" } as ContactEntity);
  }

  get address(): AddressEntity {
    return UserModule.user.address || ({} as AddressEntity);
  }

  savsaveeEdit() {
    this.$log.info("save");
  }

  goBack() {
    this.$router.go(-1);
  }

  uploadAvatar() {
    this.$log.info("uploadAvatar");
  }
}
