import Vue from "vue";
import { VuexModule, Module, Mutation, Action, getModule } from "vuex-module-decorators";
import store from "@/store";

// interface ErrorLog {
//   err: Error;
//   vm?: any;
//   info?: string;
//   url?: string;
// }

export interface ErrorLogState {
  logs: Error[];
}

@Module({ dynamic: true, store, name: "errorLog" })
class ErrorLog extends VuexModule implements ErrorLogState {
  public logs: Error[] = [];

  @Mutation
  private ADD_ERROR_LOG(log: Error) {
    this.logs.push(log);
  }

  @Mutation
  private CLEAR_ERROR_LOG() {
    this.logs.splice(0);
  }

  @Action
  public addErrorLog(log: Error) {
    Vue.$log.error(log);
    this.ADD_ERROR_LOG(log);
  }

  @Action
  public clearErrorLog() {
    this.CLEAR_ERROR_LOG();
  }
}

export const ErrorLogModule = getModule(ErrorLog);
