






























import { Component, Vue } from "vue-property-decorator";
import LayoutReport from "@/layouts/LayoutReport.vue";
import Debug from "@/components/utility/Debug.vue";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    LayoutReport,
    Debug
  }
})
export default class ReportSuccess extends Vue {
  title = "Vielen Dank";

  get description() {
    return `Ihre Nachricht wird so schnell wie möglich von unserem Partner <a href="${this.partner?.settings.websites[0].link}">${this.partner?.companyName}</a>  bearbeitet, Sie erhalten in kürze eine Bestätigung per E-Mail.`;
  }

  get partner() {
    return ReportModule.partner;
  }
}
