










import { Component, Vue, Prop } from "vue-property-decorator";

import ErrorAlert from "@/components/utility/ErrorAlert.vue";
import TopToolbarBack from "@/components/navigation/TopToolbarBack.vue";

@Component({ components: { ErrorAlert, TopToolbarBack } })
export default class LayoutNone extends Vue {
  @Prop({})
  title: string;

  constructor() {
    super();
    this.title = "";
  }
}
