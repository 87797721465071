export class UrlParser {
  constructor(private url: string, private urlParameterHandler: UrlHandler[]) {}

  /**
   * Takes the given url and parses it, return the given UrlParts as an array.
   *
   * Creates multiple parts based on "/". Each part is a sub page of the url provided.
   * Parses each sub page and creates parameter key-value pair based on "&".
   * Each key-value pair is constructed using the "=" as a delimiter.
   *
   * E.g. /help/test=my&test2=yours
   * =>
   * [
   *  {
   *    part: "help"
   *  },
   *  {
   *    part: test=my&test2=yours,
   *    parameters :
   *    [
   *      { key: "test" , value: "my"},
   *      { key: "test2", value: "yours" }
   *    ]
   *  }
   * ]
   *
   * @returns UrlParts based on the provided URL an potential parameters ( key=value ) pairs.
   */
  public async parse(): Promise<UrlParts[]> {
    const split = this.url.split("/");
    const parts: UrlParts[] = [];

    for (let i = 0; i < split.length; i++) {
      const part = split[i];
      parts.push({ part: part });

      const parameters = part.split("&");
      if (parameters.length >= 2) {
        parts[i].parameters = [];

        for (let j = 0; j < parameters.length; j++) {
          const parameter = parameters[j];
          const [key, value] = parameter.split("=");
          parts[i].parameters?.push({ key, value });

          for (const handler of this.urlParameterHandler) {
            if (handler.key === key) {
              await handler.callBack(value);
            }
          }
        }
      }
    }

    return parts;
  }
}

export interface UrlHandler {
  key: string;
  callBack: (val: string) => void;
}

export interface UrlParts {
  part: string;
  parameters?: [UrlParameter?];
}

export interface UrlParameter {
  key: string;
  value: string;
}
