import store from "@/store";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import partnerService from "../../services/mrfiktiv/services/partnerService";
import { PartnerContactEntity } from "@/models/partnerContactEntity";
import { PartnerEntity } from "../../models/partnerEntity";
import { FormEntity } from "@/models/formEntity";
import { ReportEntity } from "@/models/reportEntity";

@Module({
  dynamic: true,
  namespaced: true,
  name: "partner",
  store
})
class Partner extends VuexModule {
  partnerContact = {} as PartnerContactEntity;
  partner: PartnerEntity = {} as PartnerEntity;
  partners: PartnerEntity[] = [];
  forms: FormEntity[] = [];
  form: FormEntity = {} as FormEntity;
  formExportFile = "";
  reports: ReportEntity[] = [];
  report: ReportEntity = {} as ReportEntity;
  reportExportFile = "";
  loading = true;

  drawer = false;

  @Action
  setDrawer(val: any) {
    this.context.commit("updateDrawer", val);
  }

  @Mutation
  updateDrawer(val: any) {
    this.drawer = val;
  }

  @Action
  async getPartnerContact() {
    return this.partnerContact;
  }

  @Mutation
  setPartnerContact(partnerContact: PartnerContactEntity) {
    this.partnerContact = partnerContact;
  }

  @Action
  async sendContact() {
    let response: any;
    try {
      response = await partnerService.contact(this.partnerContact, this.partner.companyUsername);
      Vue.$log.info(response);
      Vue.$toast.success("Deine Anfrage wurde versendet.");
    } catch (error) {
      Vue.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    return response;
  }

  @Action
  async getPartnerByName(partnerName: string) {
    try {
      const response = await partnerService.getPartnerByName(partnerName);
      const partnerData = response.data as PartnerEntity;
      this.context.commit("setPartner", partnerData);
    } catch (e) {
      Vue.$toast.error(`Partner ${partnerName} nicht gefunden.`);
    }
  }

  @Action
  async getPartnerById(partnerId: string) {
    try {
      const response = await partnerService.getPartnerById(partnerId);
      const partnerData = response.data as PartnerEntity;
      this.context.commit("setPartner", partnerData);
    } catch (e) {
      Vue.$toast.error(`Partner ${partnerId} nicht gefunden.`);
    }
  }

  @Action
  async updatePartner(partner: PartnerEntity) {
    try {
      const response = await partnerService.updatePartner(partner);
      const partnerData = response.data as PartnerEntity;
      this.context.commit("setPartner", partnerData);
      Vue.$toast.success(`Partner ${partner.companyName} wurde erfolgreich geändert.`);
    } catch (e) {
      Vue.$toast.error(`Partner ${partner.companyName} konnte nicht aktualisiert werden.`);
    }
  }

  @Action
  async deletePartner(partnerId: string) {
    try {
      const response = await partnerService.deletePartner(partnerId);
      this.context.commit("setPartner", {} as PartnerEntity);
      Vue.$log.info(response);
      Vue.$toast.success("Der Parnter wurde erfolgreich gelöscht.");
    } catch (e) {
      Vue.$toast.error(`Partner ${partnerId} konnte nicht gelöscht werden.`);
    }
  }

  @Action
  async getPartner() {
    return this.partner;
  }

  @Mutation
  setPartner(partner: PartnerEntity) {
    this.partner = partner;
    this.loading = false;
  }

  @Action
  async getPartners() {
    try {
      const response = await partnerService.getAllPartners();
      const partners = response.data;
      this.context.commit("setPartners", partners);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  setPartners(partners: PartnerEntity[]) {
    this.partners = partners;
  }

  @Action
  async getAllFormsForPartner() {
    try {
      const response = await partnerService.getAllFormsForPartner(this.partner._id);
      const forms = response.data;
      this.context.commit("setForms", forms);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  setForms(forms: any[]) {
    this.forms = forms;
  }

  @Action
  async getFormByIdForPartner(formId: string) {
    try {
      const response = await partnerService.getFormById(this.partner._id, formId);
      const form = response.data;
      this.context.commit("setForm", form);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  setForm(form: any) {
    this.form = form;
  }

  @Action
  async getAllReportsForPartner() {
    try {
      const reponse = await partnerService.getAllReportsForPartner(this.partner._id);
      const reports = reponse.data;
      this.context.commit("setReports", reports);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  setReports(reports: any[]) {
    this.reports = reports;
  }

  @Action
  async getReportByIdForPartner(reportId: string) {
    try {
      const reponse = await partnerService.getReportById(this.partner._id, reportId);
      const report = reponse.data;
      this.context.commit("setReport", report);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  setReport(report: any) {
    this.report = report;
  }

  @Action
  async getExportForReportById(reportId: string) {
    try {
      const reponse = await partnerService.getExportForReportById(this.partner._id, reportId, "ksr");
      const reportExportFile = reponse.data.url;
      this.context.commit("setReportExportFile", reportExportFile);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  setReportExportFile(reportExportFile: string) {
    this.reportExportFile = reportExportFile;
  }

  @Action
  async getExportForFormById(formId: string) {
    try {
      const reponse = await partnerService.getExportForFormById(this.partner._id, formId, "ksr");
      const formExportFile = reponse.data.url;
      this.context.commit("setFormExportFile", formExportFile);
    } catch (error) {
      Vue.$log.error(error);
    }
  }

  @Mutation
  setFormExportFile(formExportFile: string) {
    this.formExportFile = formExportFile;
  }

  @Action
  async addRegistrationDetailsForForm(payload: any) {
    let response: any;
    try {
      response = await partnerService.addRegistrationDetailsForForm(
        this.partner._id,
        payload.formId,
        payload.registrationResults
      );
      Vue.$log.info(response);
    } catch (error) {
      Vue.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    return response;
  }

  @Action
  async addRegistrationDetailsForReport(payload: any) {
    let response: any;
    try {
      response = await partnerService.addRegistrationDetailsForReport(
        this.partner._id,
        payload.reportId,
        payload.registrationResults
      );
      Vue.$log.info(response);
    } catch (error) {
      Vue.$toast.error("Deine Anfrage konnte nicht versendet werden.");
    }

    return response;
  }
}

export const PartnerModule = getModule(Partner);
