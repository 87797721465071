/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuthInfo {
  /** The username of the user */
  username: string;

  /** The id of the user */
  sub: string;

  /** The issuer of the token */
  iss: string;

  /** The application id that provided the user */
  clientId: string;
}

export interface CreateCaseDto {
  /** The type of the case */
  type: "adversarialIncurance" | "ownInsurance" | "private" | "undefined";

  /** The details of the vehicle from a vehicle registration */
  vehicle: object;

  /**
   * The damages of the car
   * @example ["rear","front"]
   */
  damages: string[];

  /**
   * The upgrades for the claim
   * @example ["shippingHome","shippingInsurance"]
   */
  upgrades: string[];

  /**
   * The insurance for the claim
   * @example allianz
   */
  insurance: string;
}

export type ObjectID = object;

export interface VehicleDocument {
  /** The Vehicle Identification Number (VIN) or german Fahrzeug Identifikations Nummer (FIN) */
  vin: string;

  /** The milage of the vehicle in km */
  mileage: number;

  /** The year of construction of a vehicle */
  constructionYear: number;

  /** The name of the manufacturer of the vehicle */
  manufactureName: string;

  /** The code of the manufacturer of a vehicle */
  manufacturerCode: string;

  /** The model of the vehicle */
  modelName: string;

  /** The license plate of the vehicle */
  licensePlate: string;

  /** The code type of the vehicle */
  codeTyp: string;
}

export interface CaseDetailDocument {
  /** The overall paid price for the case */
  overallPrice: number;

  /** The products associated and purchased with the case */
  product: object;

  /** The insurance associated with the case */
  upgrades: string[];
}

export interface ActivityDocument {
  /** The id of the activity */
  id: string;

  /** The type of the activity */
  type: string;

  /** The title of the activity */
  title: string;

  /** The message of the activity */
  message: string;

  /** Activity attachment */
  attachment: string;

  /** has the activity been read? */
  readStatus: boolean;

  /** Timestamps of the activity */
  timestamp: object;
}

export interface CaseDocument {
  /**
   * The type of the case
   * @example adversarialIncurance
   */
  type: string;

  /**
   * The progress of the case
   * @example submitted
   */
  progress: string;

  /** The id of the user of the case */
  customer: ObjectID;

  /** The id of the user of the case */
  worker: ObjectID;

  /** The id of the partnerworkshop */
  workshop: ObjectID;

  /**
   * The name of the insurance
   * @example Alianz
   */
  insurance: string;

  /** The details of the vehicle from a vehicle registration */
  vehicle: VehicleDocument[];

  /**
   *  The details of the case including what packages and prices are booked
   * @example {"overallPrice":1,"products":{},"insurance":"abc123"}
   */
  detail: CaseDetailDocument[];

  /**
   * Where is the damage on the cae
   * @example ["front","frontLeft"]
   */
  damages: string[];

  /**
   * Product of the case
   * @example claim
   */
  product: string;

  /**
   * Productupgrades to the claim
   * @example ["shippingHome","expressService"]
   */
  upgrades: string[];

  /**
   * The id of the activities attached to the case
   * @example ["abc123","cde456"]
   */
  activities: ActivityDocument[];

  /**
   * The timestamps of the case
   * @example {"created":"2021-01-01T12:12:12.317Z","submitted":"2021-01-01T12:12:12.317Z","lastModified":"2021-01-01T12:12:12.317Z","modified":["2021-01-01T12:12:12.317Z","2021-01-01T12:12:12.000Z"],"closed":"2021-01-01T12:12:12.317Z"}
   */
  timestamps: object;

  /**
   * The id of images for registration, cockpits and damages attached to the case
   * @example {"registrations":{},"cockpits":{},"damages":{},"overview":{},"plates":{}}
   */
  images: object;

  /**
   * The pdfs to the case
   * @example [{"folderName":"6091b7ce9694e75d901bb8d7","timestamp":{"created":"2021-05-04T21:08:59.708Z"},"type":"calculation","name":"c657b0eed9b2ce506e2e63ab.pdf","url":"https://devapimrfiktiv.blob.core.windows.net/6091b7ce9694e75d901bb8d7/c657b0eed9b2ce506e2e63ab.pdf"}]
   */
  pdfs: string[];
}

export interface UpdateCaseDto {
  /** The type of the case */
  type: "adversarialIncurance" | "ownInsurance" | "private" | "undefined";

  /** The details of the vehicle from a vehicle registration */
  vehicle: object;

  /**
   * The damages of the car
   * @example ["rear","front"]
   */
  damages: string[];

  /**
   * The upgrades for the claim
   * @example ["shippingHome","shippingInsurance"]
   */
  upgrades: string[];

  /**
   * The insurance for the claim
   * @example allianz
   */
  insurance: string;
}

export interface AssignPartnerDto {
  /** The type of the case */
  workshopId: string;
}

export interface CreateActivityDto {
  /** The type of the case */
  type: "assigned" | "inProgress" | "completed";
}

export interface ReadActivityDto {
  /** The readstatus of the activity */
  status: boolean;
}

export interface SignUpUserDto {
  /** The username */
  username: string;

  /** The password */
  password: string;
}

export interface UserAuthDto {
  /** The username */
  username: string;

  /** The password */
  password: string;
}

export interface VerifyAuthDto {
  /** The username */
  username: string;

  /** The code */
  code: string;
}

export interface ConfirmPasswordDto {
  /** The username */
  username: string;

  /** The password */
  password: string;

  /** The code */
  code: string;
}

export interface ForgotPasswordDto {
  /** The username */
  username: string;
}

export interface CreateUserDto {
  /** The users first name */
  userName: string;

  /** The users first name */
  firstName: string;

  /** The users first name */
  lastName: string;

  /** The users contact details */
  contact: object;

  /** The users address */
  address: object;
}

export interface UserDocument {
  /** The users userName */
  userName: string;

  /** The users first name */
  firstName: string;

  /** The users first name */
  lastName: string;

  /** The users contact details */
  contact: object;

  /** The users address */
  address: object;

  /** The assigned cases */
  cases: string[];

  /** The users type information */
  type: string;

  /** The users type information */
  workshopId: ObjectID;

  /** The users external auth information */
  authInfo: object;

  /** The users authorization roles */
  roles: string[];

  /**
   * The timestamps of the user
   * @example {"created":"2021-01-01T12:12:12.317Z","lastModified":"2021-01-01T12:12:12.317Z","modified":["2021-01-01T12:12:12.317Z","2021-01-01T12:12:12.000Z"]}
   */
  timestamps: object;
}

export interface UpdateUserDto {
  /** The users first name */
  userName: string;

  /** The users first name */
  firstName: string;

  /** The users first name */
  lastName: string;

  /** The users contact details */
  contact: object;

  /** The users address */
  address: object;
}

export interface PromoteUserDto {
  /** The users authorization roles */
  roles: string[];
}

export interface SetUserTypeDto {
  /** The users authorization roles */
  type: string;
}

export interface ContactDto {
  /**
   * The email
   * @example info@mmmint.ai
   */
  email: string;

  /**
   * The phone
   * @example +4917672884125
   */
  phone: string;
}

export interface AddressDto {
  /**
   * The state
   * @example Niedersachsen
   */
  state: string;

  /**
   * The street
   * @example Auf der Halle 25a
   */
  street: string;

  /**
   * The zip code
   * @example 49124
   */
  zip: string;

  /**
   * The city
   * @example Georgsmarienhütte
   */
  city: string;
}

export interface OpeningHoursDto {
  /**
   * OpeningHours of monday
   * @example 08:00 bis 16:00
   */
  monday: string;

  /**
   * OpeningHours of tuesday
   * @example 08:00 bis 16:00
   */
  tuesday: string;

  /**
   * OpeningHours of wednesday
   * @example 08:00 bis 16:00
   */
  wednesday: string;

  /**
   * OpeningHours of thursday
   * @example 08:00 bis 16:00
   */
  thursday: string;

  /**
   * OpeningHours of friday
   * @example 08:00 bis 16:00
   */
  friday: string;

  /**
   * OpeningHours of saturday
   * @example 08:00 bis 16:00
   */
  saturday: string;

  /**
   * OpeningHours of sunday
   * @example 08:00 bis 16:00
   */
  sunday: string;
}

export interface WebsitesDto {
  /**
   * Text displayed for website link
   * @example www.mmmint.ai
   */
  text: string;

  /**
   * website link
   * @example https://www.mmmint.ai
   */
  link: string;
}

export interface EmailSettingsDto {
  /**
   * Desplay option for registrationresults in email
   * @example true
   */
  registrationShowResults: boolean;

  /**
   * Display option for ksr button in the email
   * @example true
   */
  registrationShowKsrButton: boolean;
}

export interface BookedServicesDto {
  /**
   * Registrationservice as booked service
   * @example true
   */
  registrationService: boolean;

  /**
   * Form as booked service
   * @example true
   */
  form: boolean;

  /**
   * Report as booked Service
   * @example true
   */
  report: boolean;
}

export interface ReportScreenDto {
  /**
   * Defines the type of the screen
   * @example welcome
   */
  type:
    | "welcome"
    | "registration"
    | "damagelocator"
    | "overviewimage"
    | "componentimage"
    | "damagedetail"
    | "mileage"
    | "message"
    | "contactdetails"
    | "daterequest"
    | "closing";

  /** @example So einfach geht die fiktive Schadensabrechnung mit uns: */
  title: string;

  /** @example So einfach geht die fiktive Schadensabrechnung mit uns: */
  description: string;

  /**
   * Some specific Informations about the Screens Content
   * @example {}
   */
  content: object;
}

export interface ReportSettingsDto {
  /**
   * logoUrl for form and report
   * @example https://www.mmmint.ai/images/logo.png
   */
  logoUrl: string;

  /**
   * Header Image Url
   * @example https://www.mmmint.ai/images/logo.png
   */
  headerImageUrl: string;

  /**
   * FacIconUrl
   * @example https://www.mmmint.ai/images/logo.png
   */
  favIconUrl: string;

  /**
   * Colorcode for main color
   * @example #111111
   */
  firstColor: string;

  /**
   * Color code for second color
   * @example #123456
   */
  secondColor: string;

  /** @example ["welcome","registration","damagelocator","overviewimage","componentimage","damagedetail","mileage","message","daterequest","contactdetails","closing"] */
  screenOrder: (
    | "welcome"
    | "registration"
    | "damagelocator"
    | "overviewimage"
    | "componentimage"
    | "damagedetail"
    | "mileage"
    | "message"
    | "contactdetails"
    | "daterequest"
    | "closing"
  )[];
  screens: ReportScreenDto[];
}

export interface ParnterSettingsDto {
  /**
   * logoUrl for form and report
   * @example https://www.mmmint.ai/images/logo.png
   */
  logoUrl: string;

  /**
   * headerImageUrl of form or report
   * @example https://www.mmmint.ai/images/logo.png
   */
  headerImageUrl: string;

  /**
   * fav icon url
   * @example https://www.mmmint.ai/images/logo.png
   */
  favIconUrl: string;

  /**
   * Title of the Form
   * @example Digitale Schadensabrechnung
   */
  formTitle: string;

  /**
   * color code
   * @example #888888
   */
  color: string;

  /** contact */
  contact: ContactDto;

  /** Opening Hours of the Partner */
  openingHours: OpeningHoursDto;

  /** an array of website links and names */
  websites: WebsitesDto[];

  /**
   * Title o the Card in the text
   * @example Smart Repair
   */
  cardTitle: string;

  /**
   * Subtitle of the Text in the Card
   * @example Wir reparieren Ihr Auto
   */
  cardText: string;

  /**
   * Subtilte of the Card
   * @example Jetzt Angebot anfordern
   */
  cardSubtitle: string;

  /** Email Settings of the Partner */
  emailSettings: EmailSettingsDto;

  /** Services the Partner has booked */
  bookedServices: BookedServicesDto;

  /** Defines the Setting for the Report */
  reportSettings: ReportSettingsDto;
}

export interface CreatePartnerDto {
  /** The partners company user name */
  companyUsername: string;

  /** The partners company name */
  companyName: string;

  /** The partners Contact */
  contact: ContactDto;

  /** The partners Address */
  address: AddressDto;

  /** The partners settings */
  settings: ParnterSettingsDto;
}

export interface AssignedCaseDocument {
  /** The assigned case */
  case: ObjectID;

  /** The timestamp */
  timestamp: object;
}

export interface PartnerSettingsDocument {
  /** The link to the logo asset */
  logoUrl: string;

  /** The link to the header image asset */
  headerImageUrl: string;

  /** The link to the fav icon url asset */
  favIconUrl: string;

  /** The title of the form */
  formTitle: string;

  /** The color code of partner */
  color: string;

  /** The displayed contact details of partner */
  contact: object;

  /** The opening hours of a partner */
  openingHours: object;

  /** The displayed webistes of partner */
  websites: string[];

  /** The displayed webistes of partner */
  emailSettings: object;

  /** The displayed webistes of partner */
  bookedServices: object;

  /** The displayed webistes of partner */
  reportSettings: ReportSettingsDto;

  /** The title of the card */
  cardTitle: string;

  /** The subtitle of the card */
  cardSubtitle: string;

  /** The text of the card */
  cardText: string;

  /** The name of partner */
  companyName: string;
}

export interface PartnerDocument {
  /** The company username of a partner */
  companyUsername: string;

  /** The company name */
  companyName: string;

  /** The address */
  address: object;

  /** The contact */
  contact: object;

  /** The logo */
  logo: string;

  /** The editors */
  editors: ObjectID[];

  /** Assigned cases */
  assignedCases: AssignedCaseDocument[];

  /** Settings of partner */
  settings: PartnerSettingsDocument[];

  /**
   * The timestamps of the partner
   * @example {"created":"2021-01-01T12:12:12.317Z","lastModified":"2021-01-01T12:12:12.317Z","modified":["2021-01-01T12:12:12.317Z","2021-01-01T12:12:12.000Z"]}
   */
  timestamps: object;
}

export interface UpdatePartnerDto {
  /** The partners company name */
  companyName: string;

  /** The partners Contact */
  contact: ContactDto;

  /** The partners Address */
  address: AddressDto;

  /** The partners settings */
  settings: ParnterSettingsDto;
}

export interface UpdatePartnerEditorDto {
  /** The partners editors cases */
  editors: ObjectID;
}

export interface CreateImageDto {
  /**
   * The image to upload for the case
   * @format binary
   */
  image: File;

  /**
   * The id of the case
   * @example abc123
   */
  caseId: string;

  /**
   * The type of the image
   * @example damage
   */
  type: "damage" | "damageDetail" | "plate" | "cockpit" | "overview" | "registration" | "others";
}

export interface TimestampDocument {
  /**
   * The created date
   * @format date-time
   */
  created: string;

  /**
   * The last modified date
   * @format date-time
   */
  lastModified: string;

  /** The modified date log */
  modified: string[];
}

export interface ImageDocument {
  /**
   * The id of the folder
   * @example abc123
   */
  folderName: string;

  /**
   * The name of the original image
   * @example abc123
   */
  name: string;
  url: string;

  /**
   * The type of the image
   * @example damage
   */
  type: string;

  /**
   * Is true if the picture is used in form, report or case.
   * @example damage
   */
  isUsed: boolean;

  /**
   * Is true if the picture is used in form, report or case.
   * @example damage
   */
  contactType: string;

  /** The timestamp */
  timestamp: TimestampDocument[];
}

export interface ChatIdAndOwner {
  /**
   * The id of the chat
   * @example 60b34d2f7c547f423ad17fcf
   */
  chatId: string;

  /**
   * The ids of the owners of the chat
   * @example ["60b34d2f7c547f423ad17fcf","60b34d2f7c547f423ad17fcf"]
   */
  owners: string[];
}

export interface ListOfChatsViewModel {
  /** An array of objects containing the id and owner of a chat */
  idAndOwners: ChatIdAndOwner[];
}

export interface Content {
  /**
   * The body of the message
   * @example some string
   */
  body: string;
}

export interface Message {
  /**
   * Id of the sender of the message
   * @example 886968133034
   */
  senderId: string;

  /** The content of the message */
  content: Content;

  /**
   * The timestamp of the message
   * @example 05-10-2020-18:00:02
   */
  timestamp: string;
}

export interface ChatGetViewModel {
  /**
   * The id of the chat
   * @example 60b34d2f7c547f423ad17fcf
   */
  chatId: string;

  /**
   * An array of owners
   * @example ["60b34d2f7c547f423ad17fcd","60b34d2f7c547f423ad17fcf"]
   */
  owners: string[];

  /** An array of messages */
  messages: Message;
}

export interface MessageListViewModel {
  /** A list of messages */
  messages: Message;
}

export interface CreateChatDto {
  /**
   * The ids of the chat owners
   * @example ["60b34d2f7c547f423ad17fcf","60b34d2f7c547f423ad17fcf"]
   */
  owners: string[];

  /** The content of the first message */
  content: Content;
}

export interface UpdateOwnersDto {
  /**
   * The ids of the owners that shall be added or removed.
   * @example ["60b34d2f7c547f423ad17fcf","60b34d2f7c547f423ad17fcf"]
   */
  owners: string[];
}

export interface ChatPutViewModel {
  /**
   * The id of the chat
   * @example 60b34d2f7c547f423ad17fcf
   */
  chatId: string;

  /**
   * An array of owners
   * @example ["60b34d2f7c547f423ad17fcf","60b34d2f7c547f423ad17fcf"]
   */
  owners: string[];
}

export interface AddMessageToChatDto {
  /** The message content */
  content: Content;
}

export interface MessageViewModel {
  /** A message */
  message: Message;
}

export interface CreateFormDto {
  /** Your name */
  name: string;

  /** Your Contact Email */
  email: string;

  /** Your Contact Phone */
  phone: string;

  /** The Form Title */
  title: string;

  /** The Form Text */
  message: string;

  /**
   * Image of the registration.
   * @format binary
   */
  registration: File;

  /** Attachments. */
  images: File[];
}

export interface FormDocument {
  /** The id of the partner */
  partnerId: ObjectID;

  /** Your name */
  name: string;

  /** Your Contact Details */
  contact: object;

  /** The Form Text */
  title: string;

  /** The Form Title */
  message: string;

  /** Image of the registration. */
  registration: object;

  /** Attachments. */
  attachments: string[];

  /** registration Results. */
  registrationResults: object;

  /**
   * The timestamps of the partner
   * @example {"created":"2021-01-01T12:12:12.317Z","lastModified":"2021-01-01T12:12:12.317Z","modified":["2021-01-01T12:12:12.317Z","2021-01-01T12:12:12.000Z"]}
   */
  timestamps: object;
}

export interface RegistrationDto {
  /**
   * firstname of registrationdocument
   * @example Max
   */
  firstname: object;

  /**
   * name of registrationdocument
   * @example Mustermann
   */
  name: object;

  /**
   * street of registrationdocument
   * @example Auf der Halle 25a
   */
  street: object;

  /**
   * zipCode of registrationdocument
   * @example 49124
   */
  zipCode: object;

  /**
   * city of registrationdocument
   * @example Georgsmarienhütte
   */
  city: object;

  /**
   * numberplate of registrationdocument
   * @example OS-PT 678
   */
  numberplate: object;

  /**
   * identificationnumber of registrationdocument
   * @example WVWZZZ1JW2W057177
   */
  identificationnumber: object;

  /**
   * huYear of registrationdocument
   * @example 2020
   */
  huYear: object;

  /**
   * huMonth of registrationdocument
   * @example 05
   */
  huMonth: object;

  /**
   * firstregistrationDay of registrationdocument
   * @example 05
   */
  firstregistrationDay: object;

  /**
   * firstregistrationMonth of registrationdocument
   * @example 05
   */
  firstregistrationMonth: object;

  /**
   * firstregistrationYear of registrationdocument
   * @example 2017
   */
  firstregistrationYear: object;

  /**
   * manufacturerNameCode of registrationdocument
   * @example 0005
   */
  manufacturerNameCode: object;

  /**
   * manufacturerTypeCode of registrationdocument
   * @example AHT00025
   */
  manufacturerTypeCode: object;

  /**
   * manufacturerName of registrationdocument
   * @example Volkswagen
   */
  manufacturerName: object;

  /**
   * manufacturerType of registrationdocument
   * @example Golf
   */
  manufacturerType: object;
}

export interface CreatePdfDto {
  /**
   * The pdf to upload for the case
   * @format binary
   */
  pdf: File;

  /**
   * The id of the case
   * @example abc123
   */
  caseId: string;

  /**
   * The type of the pdf
   * @example calculation
   */
  type: "calculation" | "invoice";
}

export interface PdfDocument {
  /**
   * The id of the folder
   * @example abc123
   */
  folderName: string;

  /**
   * The name of the original pdf
   * @example abc123
   */
  name: string;
  url: string;

  /**
   * The type of the pdf
   * @example calculation
   */
  type: string;

  /** The timestamp */
  timestamp: TimestampDocument[];
}

export interface CreateReportImageDto {
  /**
   * The Type of the Image
   * @example damage
   */
  type: "damage" | "damageDetail" | "plate" | "cockpit" | "overview" | "registration" | "others";

  /**
   * Image for the Report.
   * @format binary
   */
  image: File;
}

export interface CreatedImageFromReportResponseDto {
  /**
   * The Id of the Image
   * @example abc123
   */
  imageId: string;
}

export interface CreateReportImageCollectionDto {
  /**
   * Your name
   * @example ["6058b76200570592f2b2e8fd"]
   */
  registrations: string[];

  /**
   * Your name
   * @example ["6058b76200570592f2b2e8fd"]
   */
  cockpits: string[];

  /**
   * Your name
   * @example ["6058b76200570592f2b2e8fd","6058b76200570592f2b2e8fd"]
   */
  damages: string[];

  /**
   * Your name
   * @example ["6058b76200570592f2b2e8fd","6058b76200570592f2b2e8fd"]
   */
  damagesDetail: string[];

  /**
   * Your name
   * @example ["6058b76200570592f2b2e8fd","6058b76200570592f2b2e8fd"]
   */
  overviews: string[];
}

export interface CreateReportDto {
  /**
   * Your name
   * @example Max Mustermann
   */
  customerName: string;

  /**
   * Customers Numberplate
   * @example OS-MM 2021
   */
  numberplate: string;

  /** Contact informations of the customer */
  customerContact: ContactDto;

  /** Contact informations of the customer */
  customerAddress: AddressDto;

  /**
   * The Form Text
   * @example Hello i have a damaged car.
   */
  message: string;

  /**
   * The Datepreference of the customer for a termin.
   * @example 2021-06-08T10:42:30.689Z
   */
  datePreference: string;

  /** The collection of the provided images. */
  images: CreateReportImageCollectionDto;

  /**
   * The damage locations provided by the customer via damage locator.
   * @example ["tireFrontLeft"]
   */
  damage: (
    | "tireFrontLeft"
    | "tireFrontRight"
    | "tireRearLeft"
    | "tireRearRight"
    | "doorFrontLeft"
    | "doorFrontRight"
    | "doorRearLeft"
    | "doorRearRight"
    | "bumperFront"
    | "bumperRear"
    | "fenderFrontLeft"
    | "fenderFrontRight"
    | "sidepanleRearLeft"
    | "sidepanelRearRight"
    | "roof"
    | "windshield"
    | "tailgate"
    | "engingeHood"
  )[];

  /**
   * MarketingOptIn to contact customer for marketing options.
   * @example true
   */
  marketingOptIn: boolean;
}

export interface ContactDocument {
  /** The email address */
  email: string;

  /** The phone number */
  phone: string;
}

export interface BaseImageSchema {
  /**
   * The name of the folder
   * @example abc123
   */
  folderName: string;

  /**
   * The name of the original image
   * @example abc123
   */
  name: string;
  url: string;

  /**
   * The type of the image
   * @example damage
   */
  type: string;

  /** The timestamp */
  timestamp: TimestampDocument;
}

export interface BaseCollectionSchema {
  /** The collection of the images. */
  registrations: BaseImageSchema[];

  /** The collection of the images. */
  cockpits: BaseImageSchema[];

  /** The collection of the images. */
  damages: string[];

  /** The collection of the images. */
  damagesDetail: BaseImageSchema[];

  /** The collection of the images. */
  overviews: BaseImageSchema[];

  /** The collection of the images. */
  plates: BaseImageSchema[];
}

export interface ReportDocument {
  /**
   * The id of the partner
   * @example abc123
   */
  partnerId: string;

  /**
   * Name of the customer
   * @example Max Mustermann
   */
  customerName: string;

  /**
   * The customers Numberplate
   * @example OS-MM 2021
   */
  numberplate: string;

  /** Contact Details of the customer */
  customerContact: ContactDocument;

  /** Address Details of the customer */
  customerAddress: AddressDto;

  /** The Detaildiscription of the Damage provided by the customer */
  message: string;

  /** registration Results. */
  registrationResults: RegistrationDto;

  /**
   * The timestamps of the partner
   * @example {"created":"2021-01-01T12:12:12.317Z","lastModified":"2021-01-01T12:12:12.317Z"}
   */
  timestamps: TimestampDocument[];

  /** The Datepreference of the customer for a termin. */
  datePreference: string;

  /** The collection of the images. */
  images: BaseCollectionSchema[];

  /**
   * The damage locations provided by the customer via damage locator.
   * @example ["tireFrontLeft"]
   */
  damage: (
    | "tireFrontLeft"
    | "tireFrontRight"
    | "tireRearLeft"
    | "tireRearRight"
    | "doorFrontLeft"
    | "doorFrontRight"
    | "doorRearLeft"
    | "doorRearRight"
    | "bumperFront"
    | "bumperRear"
    | "fenderFrontLeft"
    | "fenderFrontRight"
    | "sidepanleRearLeft"
    | "sidepanelRearRight"
    | "roof"
    | "windshield"
    | "tailgate"
    | "engingeHood"
  )[];

  /** Marketing-Opt-In Options */
  marketingOptIn: boolean;
}

export interface CreateNewsletterDto {
  /**
   * Your name
   * @example Max Mustermann
   */
  customerName: string;

  /**
   * Customers contact Email
   * @example Email address
   */
  subscriptionEmail: string;

  /** Contact informations of the customer */
  customerContact: ContactDto;

  /** Contact informations of the customer */
  customerAddress: AddressDto;

  /**
   * Marketing-Opt-In Options
   * @example ["mrfiktiv"]
   */
  interests: ("mrfiktiv" | "mmmint" | "workshopCustomer")[];

  /**
   * Marketing-Opt-In Options
   * @example mrfiktiv
   */
  firstContact: "mrfiktiv" | "mmmint" | "workshopCustomer";
}

export interface OptInConfirmation {
  /**
   * Subscription Email.
   * @example info@mmmmint.ai
   */
  subscribtionEmail: string;

  /**
   * Newsletter Id.
   * @example 5678347568093456
   */
  newsletterId: string;

  /**
   * ConfirmationMailSend.
   * @example true
   */
  confirmationMailSend: boolean;
}

export interface MarketingOptInDTO {
  /**
   * subscribestatus of the customer.
   * @example true
   */
  subscribeStatus: boolean;

  /**
   * verified the actual status.
   * @example true
   */
  verify: boolean;

  /**
   * Documents when the verified mail was opend.
   * @example ["2021-01-01T12:12:12.317Z"]
   */
  verifyMailOpened: string[];

  /**
   * verified the actual status.
   * @example ["2021-01-01T12:12:12.317Z"]
   */
  dateVerified: string[];

  /**
   * verified the actual status.
   * @example ["2021-01-01T12:12:12.317Z"]
   */
  dateUnsubscribed: string[];
}

export interface NewsletterDocument {
  /**
   * Contact Email Address
   * @example abc123
   */
  subscriptionEmail: string;

  /** Name of the customer */
  customerName: string;

  /** Contact Details of the customer */
  customerContact: ContactDocument;

  /** Address Details of the customer */
  customerAddress: AddressDto;

  /**
   * The id of the partner
   * @example abc123
   */
  userId: string;

  /**
   * The id of the partners
   * @example abc123
   */
  partnerIds: string[];

  /**
   * Interests of the customer.
   * @example ["mrfiktiv"]
   */
  interests: ("mrfiktiv" | "mmmint" | "workshopCustomer")[];

  /**
   * Channel of the first contact.
   * @example mrfiktiv
   */
  firstContact: "mrfiktiv" | "mmmint" | "report" | "form";

  /** Marketing-Opt-In Options */
  marketingOptIn: MarketingOptInDTO;

  /**
   * Private Key of customer
   * @example abc123
   */
  key: string;

  /** The timestamps of the partner */
  timestamps: TimestampDocument;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded"
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer"
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(key => "undefined" !== typeof query[key]);
    return keys
      .map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input)
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {})
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body)
    }).then(async response => {
      const r = response as HttpResponse<T, E>;
      r.data = (null as unknown) as T;
      r.error = (null as unknown) as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then(data => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch(e => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title mrfiktiv
 * @version 0.3.1.8
 * @contact
 *
 * The mrfiktiv API description
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  authInfo = {
    /**
     * No description
     *
     * @name AppControllerGetAuthInfo
     * @summary Get users authentication information
     * @request GET:/authInfo
     * @secure
     */
    appControllerGetAuthInfo: (params: RequestParams = {}) =>
      this.request<AuthInfo, void>({
        path: `/authInfo`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      })
  };
  authRequestUser = {
    /**
     * No description
     *
     * @name AppControllerGetAuthRequestUser
     * @summary Get users authentication information
     * @request GET:/authRequestUser
     * @secure
     */
    appControllerGetAuthRequestUser: (params: RequestParams = {}) =>
      this.request<AuthInfo, void>({
        path: `/authRequestUser`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      })
  };
  health = {
    /**
     * No description
     *
     * @tags health
     * @name HealthControllerCheck
     * @summary Get the health status of the API
     * @request GET:/health
     */
    healthControllerCheck: (params: RequestParams = {}) =>
      this.request<
        {
          status?: string;
          info?: Record<string, { status?: string }>;
          error?: Record<string, { status?: string }>;
          details?: Record<string, { status?: string }>;
        },
        {
          status?: string;
          info?: Record<string, { status?: string }>;
          error?: Record<string, { status?: string }>;
          details?: Record<string, { status?: string }>;
        }
      >({
        path: `/health`,
        method: "GET",
        format: "json",
        ...params
      })
  };
  case = {
    /**
     * No description
     *
     * @tags case
     * @name CaseControllerCreate
     * @summary Create a new case
     * @request POST:/case
     * @secure
     */
    caseControllerCreate: (data: CreateCaseDto, params: RequestParams = {}) =>
      this.request<CaseDocument, void>({
        path: `/case`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags case
     * @name CaseControllerFindAll
     * @summary Get all cases
     * @request GET:/case
     * @secure
     */
    caseControllerFindAll: (params: RequestParams = {}) =>
      this.request<CaseDocument[], void>({
        path: `/case`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags case
     * @name CaseControllerFindOne
     * @summary Find a case by id
     * @request GET:/case/{id}
     * @secure
     */
    caseControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<CaseDocument, void>({
        path: `/case/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags case
     * @name CaseControllerUpdate
     * @summary Update a case by id
     * @request PATCH:/case/{id}
     * @secure
     */
    caseControllerUpdate: (id: string, data: UpdateCaseDto, params: RequestParams = {}) =>
      this.request<CaseDocument, void>({
        path: `/case/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags case
     * @name CaseControllerRemove
     * @summary Delete a case by id
     * @request DELETE:/case/{id}
     * @secure
     */
    caseControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<CaseDocument, void>({
        path: `/case/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags case
     * @name CaseControllerPay
     * @summary Pay a case by id
     * @request PATCH:/case/{id}/pay
     * @secure
     */
    caseControllerPay: (id: string, params: RequestParams = {}) =>
      this.request<CaseDocument, void>({
        path: `/case/${id}/pay`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags case
     * @name CaseControllerAssignPartner
     * @summary Assign a case to a partner
     * @request POST:/case/{id}/partner/assign
     * @secure
     */
    caseControllerAssignPartner: (id: string, data: AssignPartnerDto, params: RequestParams = {}) =>
      this.request<CaseDocument, void>({
        path: `/case/${id}/partner/assign`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags case
     * @name CaseControllerCreateActivity
     * @summary Create an activity on an case.
     * @request POST:/case/{id}/activity
     * @secure
     */
    caseControllerCreateActivity: (id: string, data: CreateActivityDto, params: RequestParams = {}) =>
      this.request<CaseDocument, void>({
        path: `/case/${id}/activity`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags case
     * @name CaseControllerSetReadStatusActivity
     * @summary Create an activity on an case.
     * @request POST:/case/{id}/activity/{activityid}/read
     * @secure
     */
    caseControllerSetReadStatusActivity: (
      id: string,
      activityid: string,
      data: ReadActivityDto,
      params: RequestParams = {}
    ) =>
      this.request<CaseDocument, void>({
        path: `/case/${id}/activity/${activityid}/read`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      })
  };
  auth = {
    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerSingUp
     * @request POST:/auth/signup
     */
    authControllerSingUp: (data: SignUpUserDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/signup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerSignIn
     * @request POST:/auth/signin
     */
    authControllerSignIn: (data: UserAuthDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/signin`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerVerify
     * @request POST:/auth/verify
     */
    authControllerVerify: (data: VerifyAuthDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/verify`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerConfirmPassword
     * @request POST:/auth/confirmPassword
     * @secure
     */
    authControllerConfirmPassword: (data: ConfirmPasswordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/confirmPassword`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerForgotPassword
     * @request POST:/auth/forgotPassword
     */
    authControllerForgotPassword: (data: ForgotPasswordDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/forgotPassword`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params
      })
  };
  user = {
    /**
     * No description
     *
     * @tags user
     * @name UserControllerCreate
     * @summary Create a new user
     * @request POST:/user
     * @secure
     */
    userControllerCreate: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<UserDocument, void>({
        path: `/user`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserControllerFind
     * @summary Get an user by auth info
     * @request GET:/user
     * @secure
     */
    userControllerFind: (params: RequestParams = {}) =>
      this.request<UserDocument, void>({
        path: `/user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserControllerFindOne
     * @summary Get an user by id
     * @request GET:/user/{id}
     * @secure
     */
    userControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<UserDocument, void>({
        path: `/user/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserControllerUpdate
     * @summary Update a user by id
     * @request PATCH:/user/{id}
     * @secure
     */
    userControllerUpdate: (id: string, data: UpdateUserDto, params: RequestParams = {}) =>
      this.request<UserDocument, void>({
        path: `/user/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserControllerFindByAuthInfo
     * @summary Get an user by auth info id
     * @request GET:/user/{id}/username
     * @secure
     */
    userControllerFindByAuthInfo: (id: string, params: RequestParams = {}) =>
      this.request<UserDocument, void>({
        path: `/user/${id}/username`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserControllerPromote
     * @summary Promote a user by id
     * @request PATCH:/user/{id}/promote
     * @secure
     */
    userControllerPromote: (id: string, data: PromoteUserDto, params: RequestParams = {}) =>
      this.request<UserDocument, void>({
        path: `/user/${id}/promote`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserControllerSettype
     * @summary Set the usertype of user by id
     * @request PATCH:/user/{id}/type
     * @secure
     */
    userControllerSettype: (id: string, data: SetUserTypeDto, params: RequestParams = {}) =>
      this.request<UserDocument, void>({
        path: `/user/${id}/type`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      })
  };
  partner = {
    /**
     * No description
     *
     * @tags partner
     * @name PartnerControllerCreate
     * @summary Create a new partner
     * @request POST:/partner
     * @secure
     */
    partnerControllerCreate: (data: CreatePartnerDto, params: RequestParams = {}) =>
      this.request<CreatePartnerDto, void>({
        path: `/partner`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags partner
     * @name PartnerControllerFindAll
     * @summary Get all partners
     * @request GET:/partner
     * @secure
     */
    partnerControllerFindAll: (params: RequestParams = {}) =>
      this.request<PartnerDocument[], void>({
        path: `/partner`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags partner
     * @name PartnerControllerFindOne
     * @summary Get an partner by id
     * @request GET:/partner/{id}
     * @secure
     */
    partnerControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<PartnerDocument, void>({
        path: `/partner/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags partner
     * @name PartnerControllerUpdate
     * @summary Update a partner by id
     * @request PATCH:/partner/{id}
     * @secure
     */
    partnerControllerUpdate: (id: string, data: UpdatePartnerDto, params: RequestParams = {}) =>
      this.request<PartnerDocument, void>({
        path: `/partner/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags partner
     * @name PartnerControllerRemove
     * @summary Delete a partner by id
     * @request DELETE:/partner/{id}
     * @secure
     */
    partnerControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<PartnerDocument, void>({
        path: `/partner/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags partner
     * @name PartnerControllerFindByName
     * @summary Get a partner by name
     * @request GET:/partner/{partnerName}/byName
     */
    partnerControllerFindByName: (partnerName: string, params: RequestParams = {}) =>
      this.request<PartnerDocument, void>({
        path: `/partner/${partnerName}/byName`,
        method: "GET",
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags partner
     * @name PartnerControllerAddEditor
     * @summary Add an editor to a partner.
     * @request POST:/partner/{id}/editor
     * @secure
     */
    partnerControllerAddEditor: (id: string, data: UpdatePartnerEditorDto, params: RequestParams = {}) =>
      this.request<PartnerDocument, void>({
        path: `/partner/${id}/editor`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags partner
     * @name PartnerControllerDeleteEditor
     * @summary Delete an editor from a partner
     * @request DELETE:/partner/{id}/editor
     * @secure
     */
    partnerControllerDeleteEditor: (id: string, data: UpdatePartnerEditorDto, params: RequestParams = {}) =>
      this.request<PartnerDocument, void>({
        path: `/partner/${id}/editor`,
        method: "DELETE",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags form
     * @name FormControllerCreate
     * @summary Submit contact form to contact a Partner.
     * @request POST:/partner/{partnerName}/contact
     */
    formControllerCreate: (partnerName: string, data: CreateFormDto, params: RequestParams = {}) =>
      this.request<FormDocument, void>({
        path: `/partner/${partnerName}/contact`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags form
     * @name FormControllerFindAll
     * @summary Get all forms
     * @request GET:/partner/{id}/contact
     * @secure
     */
    formControllerFindAll: (id: string, params: RequestParams = {}) =>
      this.request<FormDocument[], void>({
        path: `/partner/${id}/contact`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags form
     * @name FormControllerFindOne
     * @summary Get a form by id
     * @request GET:/partner/{partnerId}/contact/{formId}
     * @secure
     */
    formControllerFindOne: (partnerId: string, formId: string, params: RequestParams = {}) =>
      this.request<FormDocument, void>({
        path: `/partner/${partnerId}/contact/${formId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags form
     * @name FormControllerAddRegistration
     * @summary Add an registration details to a form
     * @request POST:/partner/{partnerId}/contact/{formId}/registration
     * @secure
     */
    formControllerAddRegistration: (
      partnerId: string,
      formId: string,
      data: RegistrationDto,
      params: RequestParams = {}
    ) =>
      this.request<FormDocument, void>({
        path: `/partner/${partnerId}/contact/${formId}/registration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags report
     * @name ReportControllerUploadImage
     * @summary Submit image for a Report.
     * @request POST:/partner/{partnerName}/report/image
     */
    reportControllerUploadImage: (partnerName: string, data: CreateReportImageDto, params: RequestParams = {}) =>
      this.request<CreatedImageFromReportResponseDto, void>({
        path: `/partner/${partnerName}/report/image`,
        method: "POST",
        body: data,
        type: ContentType.FormData,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags report
     * @name ReportControllerCreate
     * @summary Submit report form to contact a Partner.
     * @request POST:/partner/{partnerName}/report
     */
    reportControllerCreate: (
      partnerName: string,
      data: CreateReportDto,
      query?: { validate?: boolean },
      params: RequestParams = {}
    ) =>
      this.request<ReportDocument, void>({
        path: `/partner/${partnerName}/report`,
        method: "POST",
        query: query,
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags report
     * @name ReportControllerFindAll
     * @summary Get all reports
     * @request GET:/partner/{partnerId}/report
     * @secure
     */
    reportControllerFindAll: (partnerId: string, params: RequestParams = {}) =>
      this.request<ReportDocument[], void>({
        path: `/partner/${partnerId}/report`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags report
     * @name ReportControllerFindOne
     * @summary Get a report by id
     * @request GET:/partner/{partnerId}/report/{reportId}
     * @secure
     */
    reportControllerFindOne: (partnerId: string, reportId: string, params: RequestParams = {}) =>
      this.request<ReportDocument, void>({
        path: `/partner/${partnerId}/report/${reportId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags report
     * @name ReportControllerAddRegistration
     * @summary Add an registration details to a report
     * @request POST:/partner/{partnerId}/report/{reportId}/registration
     * @secure
     */
    reportControllerAddRegistration: (
      partnerId: string,
      reportId: string,
      data: RegistrationDto,
      params: RequestParams = {}
    ) =>
      this.request<ReportDocument, void>({
        path: `/partner/${partnerId}/report/${reportId}/registration`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      })
  };
  image = {
    /**
     * No description
     *
     * @tags image
     * @name ImageControllerUploadFile
     * @summary Upload Photo
     * @request POST:/image/upload
     * @secure
     */
    imageControllerUploadFile: (data: CreateImageDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/image/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params
      }),

    /**
     * No description
     *
     * @tags image
     * @name ImageControllerFindAll
     * @summary Get all images
     * @request GET:/image
     * @secure
     */
    imageControllerFindAll: (params: RequestParams = {}) =>
      this.request<ImageDocument, void>({
        path: `/image`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags image
     * @name ImageControllerFindOne
     * @summary Get an image by id
     * @request GET:/image/{id}
     * @secure
     */
    imageControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<ImageDocument, void>({
        path: `/image/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags image
     * @name ImageControllerRemove
     * @summary Delete a image by id
     * @request DELETE:/image/{id}
     * @secure
     */
    imageControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<ImageDocument, void>({
        path: `/image/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params
      })
  };
  products = {
    /**
     * No description
     *
     * @tags products
     * @name ProductControllerFindAll
     * @summary Get all products
     * @request GET:/products
     */
    productControllerFindAll: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/products`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @tags products
     * @name ProductControllerFindOne
     * @summary Get a product by name
     * @request GET:/products/{productname}
     */
    productControllerFindOne: (productname: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/products/${productname}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @tags products
     * @name ProductControllerFindUpgrades
     * @summary Get all upgrades to a product
     * @request GET:/products/{productname}/upgrades
     */
    productControllerFindUpgrades: (productname: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/products/${productname}/upgrades`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @tags products
     * @name ProductControllerFindUpgrade
     * @summary Get a specific upgrade to a product
     * @request GET:/products/{productname}/upgrades/{upgradename}
     */
    productControllerFindUpgrade: (productname: string, upgradename: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/products/${productname}/upgrades/${upgradename}`,
        method: "GET",
        ...params
      })
  };
  message = {
    /**
     * No description
     *
     * @tags message
     * @name MessageControllerGetChatsFromUserId
     * @summary Gets the chats belonging to the user
     * @request GET:/message/owner
     * @secure
     */
    messageControllerGetChatsFromUserId: (params: RequestParams = {}) =>
      this.request<ListOfChatsViewModel, void>({
        path: `/message/owner`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags message
     * @name MessageControllerGetChatByChatId
     * @summary Gets chat by id
     * @request GET:/message/{chatId}
     * @secure
     */
    messageControllerGetChatByChatId: (chatId: string, params: RequestParams = {}) =>
      this.request<ChatGetViewModel, void>({
        path: `/message/${chatId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags message
     * @name MessageControllerGetMessagesByChatId
     * @summary Get all messages from chat with chat id
     * @request GET:/message/{chatId}/messages/
     * @secure
     */
    messageControllerGetMessagesByChatId: (chatId: string, params: RequestParams = {}) =>
      this.request<MessageListViewModel, void>({
        path: `/message/${chatId}/messages/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags message
     * @name MessageControllerGetAllChats
     * @summary Get all chats
     * @request GET:/message/chat/all
     * @secure
     */
    messageControllerGetAllChats: (params: RequestParams = {}) =>
      this.request<ChatGetViewModel[], any>({
        path: `/message/chat/all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags message
     * @name MessageControllerCreateNewChat
     * @summary Creates a new chat and returns newly created ChatDocument
     * @request POST:/message
     * @secure
     */
    messageControllerCreateNewChat: (data: CreateChatDto, params: RequestParams = {}) =>
      this.request<ChatGetViewModel, void>({
        path: `/message`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags message
     * @name MessageControllerAddUsersToChat
     * @summary Adds a set of users to a chat
     * @request PUT:/message/{chatId}/addUser
     * @secure
     */
    messageControllerAddUsersToChat: (chatId: string, data: UpdateOwnersDto, params: RequestParams = {}) =>
      this.request<ChatPutViewModel, void>({
        path: `/message/${chatId}/addUser`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags message
     * @name MessageControllerRemoveUsersFromChat
     * @summary Removes a set of users from the chat
     * @request PUT:/message/{chatId}/removeUser
     * @secure
     */
    messageControllerRemoveUsersFromChat: (chatId: string, data: UpdateOwnersDto, params: RequestParams = {}) =>
      this.request<ChatPutViewModel, void>({
        path: `/message/${chatId}/removeUser`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags message
     * @name MessageControllerAddMessageToChat
     * @summary Adds a message to a chat
     * @request PUT:/message/{chatId}/addMessage
     * @secure
     */
    messageControllerAddMessageToChat: (chatId: string, data: AddMessageToChatDto, params: RequestParams = {}) =>
      this.request<MessageViewModel, void>({
        path: `/message/${chatId}/addMessage`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags message
     * @name MessageControllerDeleteChat
     * @summary Deletes chat with chatId
     * @request DELETE:/message/{chatId}/chat
     * @secure
     */
    messageControllerDeleteChat: (chatId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/message/${chatId}/chat`,
        method: "DELETE",
        secure: true,
        ...params
      })
  };
  pdf = {
    /**
     * No description
     *
     * @tags pdf
     * @name PdfControllerUploadFile
     * @summary Upload Pdf
     * @request POST:/pdf/upload
     * @secure
     */
    pdfControllerUploadFile: (data: CreatePdfDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/pdf/upload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        ...params
      }),

    /**
     * No description
     *
     * @tags pdf
     * @name PdfControllerFindAll
     * @summary Get all pdfs
     * @request GET:/pdf
     * @secure
     */
    pdfControllerFindAll: (params: RequestParams = {}) =>
      this.request<PdfDocument, void>({
        path: `/pdf`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags pdf
     * @name PdfControllerFindOne
     * @summary Get an pdf by id
     * @request GET:/pdf/{id}
     * @secure
     */
    pdfControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<PdfDocument, void>({
        path: `/pdf/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags pdf
     * @name PdfControllerRemove
     * @summary Delete a pdf by id
     * @request DELETE:/pdf/{id}
     * @secure
     */
    pdfControllerRemove: (id: string, params: RequestParams = {}) =>
      this.request<PdfDocument, void>({
        path: `/pdf/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params
      })
  };
  newsletter = {
    /**
     * No description
     *
     * @tags newsletter
     * @name NewsletterControllerCreate
     * @summary Suscribe for newsletter.
     * @request POST:/newsletter
     */
    newsletterControllerCreate: (data: CreateNewsletterDto, params: RequestParams = {}) =>
      this.request<OptInConfirmation, void>({
        path: `/newsletter`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags newsletter
     * @name NewsletterControllerFindAll
     * @summary Get all newsletter
     * @request GET:/newsletter
     * @secure
     */
    newsletterControllerFindAll: (params: RequestParams = {}) =>
      this.request<NewsletterDocument[], void>({
        path: `/newsletter`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags newsletter
     * @name NewsletterControllerFind
     * @summary Get an newsletter by id
     * @request GET:/newsletter/{id}
     * @secure
     */
    newsletterControllerFind: (id: string, params: RequestParams = {}) =>
      this.request<NewsletterDocument, void>({
        path: `/newsletter/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params
      }),

    /**
     * No description
     *
     * @tags newsletter
     * @name NewsletterControllerDeleteEditor
     * @summary Delete newsletter by id
     * @request DELETE:/newsletter/{id}
     * @secure
     */
    newsletterControllerDeleteEditor: (id: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/newsletter/${id}`,
        method: "DELETE",
        secure: true,
        ...params
      }),

    /**
     * No description
     *
     * @tags newsletter
     * @name NewsletterControllerVerify
     * @summary Verify newslettersubsrciption.
     * @request GET:/newsletter/{id}/verify/{key}
     */
    newsletterControllerVerify: (id: string, key: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/newsletter/${id}/verify/${key}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @tags newsletter
     * @name NewsletterControllerUnsubscribe
     * @summary Unsubscribe from newsletter
     * @request GET:/newsletter/{id}/unsubscribe/{key}
     */
    newsletterControllerUnsubscribe: (id: string, key: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/newsletter/${id}/unsubscribe/${key}`,
        method: "GET",
        ...params
      }),

    /**
     * No description
     *
     * @tags newsletter
     * @name NewsletterControllerMailOpend
     * @summary Tracks when the mail is opened.
     * @request GET:/newsletter/{id}/mailopend/{key}
     */
    newsletterControllerMailOpend: (id: string, key: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/newsletter/${id}/mailopend/${key}`,
        method: "GET",
        ...params
      })
  };
}
