import Contact from "@/views/Contact.vue";
import PartnerContactError from "@/views/PartnerContactError.vue";
import PartnerContactSuccess from "@/views/PartnerContactSuccess.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/success",
    name: "PartnerContactSuccess",
    component: PartnerContactSuccess
  },
  {
    path: "/error",
    name: "PartnerContactError",
    component: PartnerContactError
  },
  {
    path: "*",
    name: "Contact",
    component: Contact
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: "/form/"
});

export default router;
