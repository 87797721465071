import { UrlHandler, UrlParser } from "@/lib/utility/url-parser";
import { AuthRolesEnum } from "@/store/enum/authRolesEnum";
import { UserEntity } from "@/models/userEntity";
import { ConfigModule } from "@/store/modules/config";
import { ErrorLogModule } from "@/store/modules/error-log";
import { UserModule } from "@/store/modules/user";
import { Component, Vue } from "vue-property-decorator";
@Component({})
export class VueAuth extends Vue {
  loading = true;

  isAuthenticated? = false;
  user?: UserEntity;
  error?: Error;

  access_token?: string;

  urlParamterHandlers: UrlHandler[] = [{ key: "access_token", callBack: this.handleAccessToken }];

  // @Watch("$route", { immediate: true, deep: true })
  // async onUrlChange(newVal: Route) {
  //   await this.fetchUserByAccessToken(newVal.path);
  // }

  async getUser(): Promise<UserEntity | undefined> {
    this.loading = true;

    if (!ConfigModule.mrfiktivAccessToken)
      throw new AccessTokenNotProvidedError("Access token not provided, User not authenticated");
    let user;
    try {
      user = await UserModule.getCurrentUser();
    } catch (error) {
      Vue.$log.error(error);
      this.$auth.logout();
    }

    if (user) {
      this.user = user;
      this.isAuthenticated = true;
      return user;
    }
  }

  async fetchUserByAccessToken(url: string) {
    this.loading = true;

    try {
      const parser = new UrlParser(url, this.urlParamterHandlers);
      await parser.parse();

      await this.getUser();
    } catch (error) {
      this.error = error;
      if (error instanceof AccessTokenNotProvidedError) {
        Vue.$log.warn(error);
      } else {
        Vue.$log.error(error);
        ErrorLogModule.addErrorLog({
          name: "User Error",
          message: error
        });
      }
    } finally {
      this.loading = false;
    }
  }

  async init() {
    const url = window.location.hash || window.location.pathname;
    await this.fetchUserByAccessToken(url);
  }

  handleAccessToken(val: string) {
    this.access_token = val;
    ConfigModule.updateMrfiktivAccessToken(val);
  }

  public loginWithRedirect(a: any) {
    Vue.$log.info(a);
    const url = ConfigModule.loginUrl;
    window.location["replace"](url);
  }

  public isAdmin(): boolean | undefined {
    if (!this.user) return false;
    return this.user?.roles.includes(AuthRolesEnum.ADMIN);
  }

  public isPartner(): boolean | undefined {
    if (!this.user) return false;
    return this.user?.roles.includes(AuthRolesEnum.PARTNER);
  }

  public loginWithSheet() {
    ConfigModule.makeLoginSheetVisible();
  }

  public logout() {
    // TODO: implement this via 41
    this.$log.warn("logout");
    ConfigModule.logoutMrfiktiv();
    UserModule.logout();
    window.location["replace"]("/");
  }
}

class AccessTokenNotProvidedError extends Error {
  constructor(m: string) {
    super(m);
    Object.setPrototypeOf(this, AccessTokenNotProvidedError.prototype);
  }
}
