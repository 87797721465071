


















































import BackAndForthNav from "@/components/navigation/BackAndForthNav.vue";
import LoginList from "@/components/profile/LoginList.vue";
import LogoutList from "@/components/profile/LogoutList.vue";
import ProfileShort from "@/components/profile/ProfileShort.vue";
import AboutList from "@/components/settings/AboutList.vue";
import AdminList from "@/components/settings/AdminList.vue";
import DeveloperSettingsList from "@/components/settings/DeveloperSettingsList.vue";
import SettingsList from "@/components/settings/SettingsList.vue";
import PartnerFooter from "@/components/partner/PartnerFooter.vue";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { ReportModule } from "@/store/modules/report.store";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    AboutList,
    AdminList,
    ProfileShort,
    LoginList,
    LogoutList,
    SettingsList,
    BackAndForthNav,
    DeveloperSettingsList,
    PartnerFooter
  }
})
export default class LayoutReport extends Vue {
  private defaultLogoUrl = "../../assets/logo.png";
  // <!-- box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 7%), 0px 1px 10px 0px rgb(0 0 0 / 6%) -->

  @Prop({ default: false })
  private disableNext!: boolean;

  async mounted() {
    this.checkFavIcon();
  }

  get partner() {
    return ReportModule.partner;
  }

  get logoUrl() {
    return ReportModule.partner.settings?.logoUrl;
  }

  get color() {
    return getDefaultPartnerColor();
  }

  get backgroundColor() {
    switch (this.$vuetify.breakpoint.name) {
      case "md":
        return getDefaultPartnerColor();
      case "lg":
        return getDefaultPartnerColor();
      case "xl":
        return getDefaultPartnerColor();
      default:
        return "";
    }
  }

  get currentRouteName() {
    return this.$route.name;
  }

  get StepsCount() {
    return ReportModule.MAX_STEP;
  }

  // get disableNext() {
  //   return ReportModule.disableNext;
  // }

  get nextActionName() {
    return "Schaden";
  }

  get displayedStep() {
    return this.currentStep - 1;
  }

  get displayedStepsCount() {
    return ReportModule.MAX_STEP - 1;
  }

  get currentProgress() {
    return (ReportModule.currentStep / ReportModule.MAX_STEP) * 100;
  }

  get currentStep(): number {
    return ReportModule.currentStep;
  }

  get hasNext() {
    return ReportModule.currentStep != ReportModule.MAX_STEP;
  }

  get hasPrev() {
    return ReportModule.currentStep != ReportModule.MIN_STEP;
  }

  get nextAction(): number {
    const next = ReportModule.currentStep + 1;
    if (next > ReportModule.MAX_STEP) return ReportModule.MAX_STEP;
    return next;
  }

  get prevAction(): number {
    const next = ReportModule.currentStep - 1;
    if (next < ReportModule.MIN_STEP) return ReportModule.MIN_STEP;
    return next;
  }

  get drawer() {
    return ReportModule.drawer;
  }

  set drawer(val: any) {
    ReportModule.setDrawer(val);
  }

  toggleDrawer() {
    ReportModule.setDrawer(!ReportModule.drawer);
  }

  next() {
    const next = this.nextAction;
    ReportModule.setStep(next);
    this.$router.push({ path: `/${next}` });
  }

  previous() {
    const prev = this.prevAction;
    ReportModule.setStep(prev);
    this.$router.push({ path: `/${prev}` });
  }

  checkHeaderLogo() {
    if (this.partner.settings?.reportSettings?.logoUrl) {
      return this.partner.settings?.reportSettings?.logoUrl;
    }

    if (this.partner.settings?.logoUrl) {
      return this.partner.settings?.logoUrl;
    }

    return this.defaultLogoUrl;
  }

  checkFavIcon() {
    const favIcon: any = document.querySelector("link[rel~='icon']");

    if (this.partner.settings?.reportSettings?.favIconUrl) {
      favIcon.href = this.partner.settings?.reportSettings?.favIconUrl;
      return;
    }

    if (this.partner.settings?.favIconUrl) {
      favIcon.href = this.partner.settings.favIconUrl;
      return;
    }

    favIcon.href = this.partner.settings.websites[0].link + "favicon.ico";
  }
}
