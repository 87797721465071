import { useAuth } from "@/auth/VueAuthSingleton";
import store from "@/store";
import UserService from "@/services/mrfiktiv/services/userService";
import { UserEntity } from "@/models/userEntity";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ErrorLogModule } from "./error-log";

@Module({
  dynamic: true,
  namespaced: true,
  name: "user",
  store
})
class User extends VuexModule {
  user = {} as UserEntity;

  @Mutation
  setUser(user: UserEntity) {
    this.user = user;
  }

  @Action
  async logout() {
    this.context.commit("setUser", {} as UserEntity);
  }

  @Action
  async getUser(userId: string) {
    try {
      const user = await UserService.get(userId);
      this.context.commit("setUser", user);
    } catch (error) {
      Vue.$log.error(error);
      ErrorLogModule.addErrorLog({
        name: "User Error",
        message: `Could not load User ${userId}`
      });
      // this.$auth.logout();
    }
  }

  @Action
  async getCurrentUser() {
    try {
      const user = await UserService.getByAuthInfo();
      this.context.commit("setUser", user);
      return user;
    } catch (error) {
      Vue.$log.error(error);
      ErrorLogModule.addErrorLog({
        name: "User Error",
        message: "Could not fetch current User"
      });
      useAuth().logout();
    }
  }
}

export const UserModule = getModule(User);
