

















import { Component, Vue } from "vue-property-decorator";

@Component
export default class LoginList extends Vue {
  login() {
    this.$auth.loginWithRedirect({ appState: { targetUrl: "/" } });
  }
}
