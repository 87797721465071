

















































import { Component, Vue, Prop } from "vue-property-decorator";
import { isImage } from "@/lib/files/ImageHelper";

/**
 * Component that allows a single file to be uploaded.
 * Displays an example image which is overwritten by the uploaded one.
 * Displays a loading animation via property.
 * Displays a different message based on isUploaded flag.
 */
@Component({})
export default class ImageUploadButton extends Vue {
  /**
   * Color of the icon.
   */
  @Prop({})
  iconColor?: string;

  /**
   * Icon to be displayed on the button card.
   */
  @Prop({ default: "mdi-camera" })
  icon: any;

  /**
   * Text to be displayed on the button card.
   */
  @Prop({ default: "Foto aufnehmen" })
  text: any;

  /**
   * An alternative text to be displayed on @isUploade
   */
  @Prop({ default: "Foto erneut aufnehmen?" })
  textAlt: any;

  /**
   * Indicates wether a given file is uploaded or not.
   * Changes in display behaviour based on this sate, e.g. alt text.
   */
  @Prop({ default: false })
  isUploaded!: boolean;

  /**
   * Indicates wether a given file is currently uploading or not.
   * Changes in display behaviour based on this sate, e.g. loading spinner.
   */
  @Prop({ default: false })
  isLoading!: boolean;

  /**
   * Displays either the text of the alternative text based on the upload status.
   */
  get displayText() {
    return this.isUploaded ? this.textAlt : this.text;
  }

  /**
   * Input method for handling file event.
   */
  private onFileInputEvent(event: any) {
    const file = event.target.files[0];
    this.handleImageFileInput(file);
  }

  /**
   * Private function to handle the on file input event.
   */
  private handleImageFileInput(file: File) {
    if (isImage(file)) {
      this.emitUploadedFile(file);
    } else {
      this.displayNotAnImage();
    }
  }

  /**
   * Emit an event to the partent vue component.
   */
  public emitUploadedFile(file: File) {
    this.$emit("uploaded", file);
  }

  /**
   * Helper method to display error message.
   */
  private displayNotAnImage() {
    this.$log.error("Not an image found");
    this.$toast.error("Bitte ausschließlich Bilder hochladen.");
  }
}
