





































import { Component, Vue } from "vue-property-decorator";

import LayoutReport from "@/layouts/LayoutReport.vue";
import LoaderReport from "@/components/utility/LoaderReport.vue";
import { ReportModule } from "@/store/modules/report.store";

@Component({
  components: {
    LayoutReport,
    LoaderReport
  }
})
export default class ReportHome extends Vue {
  get loading() {
    return ReportModule.loading;
  }
  title = "So schnell können Sie einen Schaden bei uns melden:";

  items = [
    { text: "Foto vom Fahrzeugschein und Schaden hochladen", icon: "mdi-camera" },
    { text: "Kontaktdaten hinterlassen", icon: "mdi-account" },
    { text: "Optional: Unfallhergang beschreiben und  Termin wählen", icon: "mdi-calendar-check" }
  ];

  start() {
    ReportModule.setStep(2);
    this.$router.push({ path: "/2" });
  }

  async mounted() {
    const partnerName = this.$route.params.partnerName;
    if (partnerName) {
      ReportModule.setPartnerName(partnerName);
      await ReportModule.getPartnerByName(partnerName);
    }
  }
}
