import { ConfigModule } from "@/store/modules/config";
import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { PartnerEntity } from "@/models/partnerEntity";
import { IReport } from "@/models/Report/IReport";
import { ReportImageType } from "@/models/Report/ReportImageType";
import { IAddress } from "@/models/User/IAddress";
import { IContact } from "@/models/User/IContact";
import { IUser } from "@/models/User/IUser";
import { ReportModule } from "@/store/modules/report.store";
import Vue from "vue";
import {
  CreatedImageFromReportResponseDto,
  CreateReportImageDto,
  PartnerDocument,
  ReportDocument
} from "../v1/mrfiktiv.api";
import { CreateReportDto } from "./../v1/mrfiktiv.api";
import { BaseServiceClient } from "./abstractService";

export class ReportServiceClient extends BaseServiceClient {
  client: any;

  constructor() {
    super();
    this.client = this.api.partner;
  }

  async initialize() {
    let partner: PartnerEntity;

    try {
      partner = await this.findPartner(ReportModule.partnerName);
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    if (!partner) {
      throw "Partner Not Found Error";
    }

    Vue.$log.info(`Fetched Partner ${partner.companyName}`);
    ReportModule.setPartnerDetails(partner);
  }

  async findPartner(partnerName: string): Promise<any> {
    let partner: PartnerDocument;
    try {
      const response = await this.client.partnerControllerFindByName(partnerName);
      partner = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }

    return partner;
  }

  async create(
    createReport: IReport,
    customer: IUser,
    customerAddress: IAddress,
    customerContact: IContact,
    numberplate: string,
    partnerName: string
  ): Promise<any> {
    let report: ReportDocument;

    if (!partnerName) {
      throw Error("Partner not set.");
    }

    const createReportDto: CreateReportDto = {
      customerAddress: { ...customerAddress },
      customerContact: {
        ...customerContact
      },
      customerName: `${customer.firstName} ${customer.lastName}`,
      damage: createReport.damageLocation as any,
      datePreference: createReport.datePreferences,
      images: {
        cockpits: createReport.cockpits.map(x => x.uploadId),
        damages: createReport.damages.map(x => x.uploadId),
        damagesDetail: createReport.damagesDetail.map(x => x.uploadId),
        overviews: createReport.overviews.map(x => x.uploadId),
        registrations: createReport.registrations.map(x => x.uploadId)
      },
      message: createReport.message,
      marketingOptIn: createReport.isMarketingOptIn,
      numberplate: numberplate
    };

    try {
      const response = await this.client.reportControllerCreate(partnerName, createReportDto);
      report = response.data;
    } catch (error) {
      Vue.$log.error(error);
      throw error;
    }
    return report;
  }

  async addImage(image: File, type: ReportImageType, partnerName: string): Promise<IImageUploaded> {
    const createReportImageDto: CreateReportImageDto = {
      image: image,
      type: type as any
    };

    const response = await this.client.reportControllerUploadImage(partnerName, createReportImageDto);
    const uploadedImage = response.data as CreatedImageFromReportResponseDto;

    return {
      uploadId: uploadedImage.imageId,
      file: image,
      isUploaded: true
    } as IImageUploaded;
  }
}

let instance: ReportServiceClient;

export const useReportServiceClient = async () => {
  if (instance) {
    instance.baseUrl = ConfigModule.mrfiktivAccessToken;
    instance.access_token = ConfigModule.mrfiktivBaseUrl;
    return instance;
  }
  instance = new ReportServiceClient();
  instance.baseUrl = ConfigModule.mrfiktivAccessToken;
  instance.access_token = ConfigModule.mrfiktivBaseUrl;
  await instance.initialize();
  return instance;
};
