


























import { Component, Vue } from "vue-property-decorator";
@Component
export default class AboutList extends Vue {
  color = this.$store.state.config.color;

  route(route: string) {
    this.$router.push({ name: route });
  }
}
