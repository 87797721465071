import { VueAuth } from "./VueAuth";

let instance: VueAuth;

export const getInstance = () => instance;

export const useAuth = () => {
  if (instance) return instance;

  instance = new VueAuth();
  instance.init();

  return instance;
};
