




























import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class InstructionCard extends Vue {
  @Prop({ default: "Anleitung" })
  instructionTitle!: string;

  @Prop()
  instructionText!: string;

  @Prop()
  instructionPictures!: string[];

  clicked() {
    this.$emit("clicked", true);
  }

  get hasMultipleImages(): boolean {
    if (this.instructionPictures) return this.instructionPictures.length >= 2;

    return false;
  }
}
