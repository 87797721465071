




























import { Component, Vue } from "vue-property-decorator";
import { ConfigModule } from "@/store/modules/config";
import { ErrorLogModule } from "@/store/modules/error-log";

@Component
export default class DeveloperSettingsList extends Vue {
  set debug(bo: boolean) {
    ConfigModule.setDebug(bo);
  }

  get debug() {
    return ConfigModule.debug;
  }

  set developmentMode(bo: boolean) {
    ConfigModule.setDevelopmentMode(bo);
  }

  get developmentMode() {
    return ConfigModule.developmentMode;
  }

  get baseUrl() {
    return ConfigModule.mrfiktivBaseUrl;
  }

  generateError() {
    ErrorLogModule.addErrorLog({
      name: "name",
      message: "message"
    });
  }
}
