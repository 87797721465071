
















































































import EditProfile from "@/components/profile/EditProfile.vue";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";

import { ReportModule } from "@/store/modules/report.store";
import { Component, Vue } from "vue-property-decorator";

import { requiredRule } from "@/lib/rules/requiredRule.ts";
import { characterCountRule } from "@/lib/rules/characterCountRule.ts";
import { emailRule, phoneRule } from "@/lib/rules/contactRule.ts";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";

@Component({
  components: {
    LayoutReportStep,
    EditProfile
  }
})
export default class ReportPersonalData extends Vue implements IReportStep {
  public md = 4;
  public header: IReportHeaderData = {
    title: "Wie können wir Sie erreichen?",
    description: "Zur Bearbeitung des Vorgangs benötigen wir nachfolgende Informationen von Ihnen."
  };

  numberplateHint = "Zum Beispiel: B-AB 123, OS-M 3E oder HH-YZ 9999H";
  /*
    They consist of an area code of one, two or three letters,            3
    a dash,                                                               1
    followed by an identifier sequence of one or two letters               2
    a space,                                                              1
    one to four digits.                                                   4
    one letter for electic or vintage.                                    1
                                                                        ---
    The total quantity of letters on the plate must not exceed eight.    12
    But we must asume there are special cases, so we add additonal 3  ¯\_(ツ)_/¯
  */

  numberplateCounter = 3 + 1 + 2 + 1 + 4 + 1 + +3;
  phone: any;

  get isDone() {
    return (
      !!this.numberplate &&
      !!this.address.zip &&
      !!this.address.street &&
      !!this.contact.email &&
      !!this.contact.phone &&
      !!this.user.firstName &&
      !!this.user.lastName
    );
  }

  get numberPlateRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(characterCountRule(this.numberplateCounter));

    return rules;
  }

  get emailRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(emailRule());

    return rules;
  }

  get phoneNumberRules() {
    const rules = [];

    rules.push(requiredRule());
    rules.push(phoneRule());

    return rules;
  }

  get numberplate(): string {
    return ReportModule.numberplate;
  }

  get user(): any {
    return ReportModule.user;
  }

  get contact(): any {
    return ReportModule.contact;
  }

  get address(): any {
    return ReportModule.address;
  }

  updateNumberplate(e: any) {
    ReportModule.setNumberplate(e.target.value);
  }
  updateUserFirstName(e: any) {
    ReportModule.setUser({ firstName: e.target.value });
  }

  updateUserLastName(e: any) {
    ReportModule.setUser({ lastName: e.target.value });
  }

  updateUserPhone(e: any) {
    ReportModule.setContact({ phone: e.target.value });
  }

  updateUserEmail(e: any) {
    ReportModule.setContact({ email: e.target.value });
  }

  updateUserCity(e: any) {
    ReportModule.setAddress({ city: e.target.value });
  }

  updateUserState(e: any) {
    ReportModule.setAddress({ state: e.target.value });
  }

  updateUserStreet(e: any) {
    ReportModule.setAddress({ street: e.target.value });
  }

  updateUserZip(e: any) {
    ReportModule.setAddress({ zip: e.target.value });
  }
}
