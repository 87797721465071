
























import { Component, Vue } from "vue-property-decorator";

import LayoutReportStep from "@/layouts/LayoutReportStep.vue";

import { ReportModule } from "@/store/modules/report.store";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";

import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";

@Component({
  components: {
    LayoutReportStep
  }
})
export default class ReportDatePicker extends Vue implements IReportStep {
  header: IReportHeaderData = {
    title: "Terminwunsch",
    description: "Bitte wählen Sie Ihren Wunschtermin für einen Besuch in der Werkstatt."
  };

  get isDone() {
    return true;
  }

  get date() {
    return ReportModule.inCreationReport.datePreferences || new Date().toISOString().substr(0, 10);
  }

  get color() {
    return getDefaultPartnerColor();
  }

  updateDate(date: string) {
    ReportModule.setDatePreference(date);
  }

  disablePastDates(val: string) {
    return val >= new Date().toISOString().substr(0, 10);
  }
}
