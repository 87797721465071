import { IImageUploaded } from "@/models/Image/IImageUploaded";
import { PartnerEntity } from "@/models/partnerEntity";
import { IReport } from "@/models/Report/IReport";
import { IUser } from "@/models/User/IUser";
import partnerService from "@/services/mrfiktiv/services/partnerService";
import { ReportServiceClient, useReportServiceClient } from "@/services/mrfiktiv/services/reportService";
import store from "@/store";
import Vue from "vue";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ReportImageType } from "../../models/Report/ReportImageType";
import { IAddress } from "../../models/User/IAddress";
import { IContact } from "../../models/User/IContact";
import { ErrorLogModule } from "./error-log";

@Module({
  dynamic: true,
  namespaced: true,
  name: "report",
  store
})
export class ReportStore extends VuexModule {
  MAX_STEP = 11;
  MIN_STEP = 1;

  currentStep = 1;
  disableNext = false;

  drawer = false;

  partnerName = "mrfiktiv";
  loading = true;

  partner: PartnerEntity = {} as PartnerEntity;
  client?: ReportServiceClient;

  EMPTY_REPORT: IReport = {
    message: "",
    damageLocation: [],
    registrations: [],
    cockpits: [],
    damages: [],
    damagesDetail: [],
    overviews: [],
    datePreferences: "",
    isMarketingOptIn: false,
    isPrivacyChecked: false
  };

  inCreationReport: IReport = this.EMPTY_REPORT;

  cockpits: IImageUploaded[] = [];
  damages: IImageUploaded[] = [];
  damagesDetail: IImageUploaded[] = [];

  overviews: IImageUploaded[] = [];
  overviewsFrontLeft: IImageUploaded[] = [];
  overviewsFrontRight: IImageUploaded[] = [];
  overviewsRearLeft: IImageUploaded[] = [];
  overviewsRearRight: IImageUploaded[] = [];

  registrations: IImageUploaded[] = [];

  EMPTY_USER: IUser = {
    firstName: "",
    lastName: ""
  };
  user = this.EMPTY_USER;

  EMPTY_CONTACT: IContact = {
    phone: "",
    email: ""
  };
  contact = this.EMPTY_CONTACT;

  EMPTY_ADDRESS: IAddress = {
    street: "",
    state: "",
    city: "",
    zip: ""
  };
  address = this.EMPTY_ADDRESS;

  numberplate = "";

  uploadErrorMessage = "Bild Upload fehlgeschlagen.";
  uploadSuccessMessage = "Bild erfolgreich hochgeladen.";
  deleteErrorMessage = "Bild löschen fehlgeschlagen.";

  @Action
  setNumberplate(numberplate: string) {
    this.context.commit("updateNumberplate", numberplate);
  }

  @Mutation
  updateNumberplate(numberplate: string) {
    this.numberplate = numberplate;
  }

  @Action
  setDatePreference(date: string) {
    this.context.commit("updateDatePreference", date);
  }

  @Mutation
  updateDatePreference(date: string) {
    this.inCreationReport.datePreferences = date;
  }

  @Action
  setReviewed(bool: boolean) {
    this.context.commit("updateReviewed", bool);
  }

  @Action
  setPrivacyCheck(bool: boolean) {
    this.context.commit("updatePrivacyCheck", bool);
  }

  @Action
  setMarketingContactAllowed(bool: boolean) {
    this.context.commit("updateMarketingContactAllowed", bool);
  }

  @Mutation
  updatePrivacyCheck(bool: boolean) {
    this.inCreationReport.isPrivacyChecked = bool;
  }

  @Mutation
  updateMarketingContactAllowed(bool: boolean) {
    this.inCreationReport.isMarketingOptIn = bool;
  }

  @Action
  setMessage(message: string) {
    this.inCreationReport.message = message;
  }

  @Action
  setUser(user: Partial<IUser>) {
    this.context.commit("updateUser", { ...this.user, ...user });
  }

  @Action
  setContact(contact: Partial<IContact>) {
    this.context.commit("updateContact", { ...this.contact, ...contact });
  }

  @Action
  setAddress(address: Partial<IAddress>) {
    this.context.commit("updateAddress", { ...this.address, ...address });
  }

  @Mutation
  updateUser(user: IUser) {
    this.user = user;
  }

  @Mutation
  updateAddress(address: IAddress) {
    this.address = address;
  }

  @Mutation
  updateContact(contact: IContact) {
    this.contact = contact;
  }

  @Action
  pushDamageLocation(location: string) {
    // this.inCreationReport.damage.push(location as any);
    const existingDamageLocation = this.inCreationReport.damageLocation;
    existingDamageLocation.push(location as any);
    this.updateReport({ damageLocation: existingDamageLocation });
  }

  @Action
  popDamageLocation(location: string) {
    const index = this.inCreationReport.damageLocation.indexOf(location as any, 0);
    if (index > -1) {
      this.inCreationReport.damageLocation.splice(index, 1);
    }
    this.updateReport({ damageLocation: this.inCreationReport.damageLocation });
  }

  @Action
  async addRegistration(file: File) {
    try {
      const imageResult = await uploadReportImage(this.client, file, ReportImageType.registration, this.partnerName);
      this.context.commit("addToRegistrations", imageResult);
    } catch (error) {
      this.context.commit("updateUploadErrorMessage", error);
    }
  }

  @Action
  async removeRegistration(file: File): Promise<boolean> {
    this.context.commit("removeFromRegistrations", file);
    return true;
  }

  @Action
  async addCockpit(file: File) {
    try {
      const imageResult = await uploadReportImage(this.client, file, ReportImageType.cockpit, this.partnerName);
      this.context.commit("addToCockpits", imageResult);
    } catch (error) {
      this.context.commit("updateUploadErrorMessage", error);
    }
  }

  @Action
  async removeCockpit(file: File): Promise<boolean> {
    this.context.commit("removeFromCockpits", file);
    return true;
  }

  @Action
  async addDamage(file: File) {
    try {
      const imageResult = await uploadReportImage(this.client, file, ReportImageType.damage, this.partnerName);
      this.context.commit("addToDamages", imageResult);
    } catch (error) {
      this.context.commit("updateUploadErrorMessage", error);
    }
  }

  @Action
  async removeDamage(file: File): Promise<boolean> {
    this.context.commit("removeFromDamages", file);
    return true;
  }

  @Action
  async addDamageDetail(file: File) {
    try {
      const imageResult = await uploadReportImage(this.client, file, ReportImageType.damageDetail, this.partnerName);
      this.context.commit("addToDamagesDetail", imageResult);
    } catch (error) {
      this.context.commit("updateUploadErrorMessage", error);
    }
  }

  @Action
  async removeDamageDetail(file: File): Promise<boolean> {
    this.context.commit("removeFromDamagesDetail", file);
    return true;
  }

  @Action
  async addOverview(file: File) {
    try {
      const imageResult = await uploadReportImage(this.client, file, ReportImageType.overview, this.partnerName);
      this.context.commit("addToOverviews", imageResult);
    } catch (error) {
      this.context.commit("updateUploadErrorMessage", error);
    }
  }

  @Action
  async removeOverview(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviews", file);
    return true;
  }

  @Action
  async addOverviewFrontLeft(file: File) {
    try {
      const imageResult = await uploadReportImage(this.client, file, ReportImageType.overview, this.partnerName);
      this.context.commit("addToOverviewsFrontLeft", imageResult);
    } catch (error) {
      this.context.commit("updateUploadErrorMessage", error);
    }
  }

  @Action
  async removeOverviewFrontLeft(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviewsFrontLeft", file);
    return true;
  }

  @Action
  async addOverviewRearLeft(file: File) {
    try {
      const imageResult = await uploadReportImage(this.client, file, ReportImageType.overview, this.partnerName);
      this.context.commit("addToOverviewsRearLeft", imageResult);
    } catch (error) {
      this.context.commit("updateUploadErrorMessage", error);
    }
  }

  @Action
  async removeOverviewRearLeft(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviewsRearLeft", file);
    return true;
  }

  @Action
  async addOverviewFrontRight(file: File) {
    try {
      const imageResult = await uploadReportImage(this.client, file, ReportImageType.overview, this.partnerName);
      this.context.commit("addToOverviewsFrontRight", imageResult);
    } catch (error) {
      this.context.commit("updateUploadErrorMessage", error);
    }
  }

  @Action
  async removeOverviewFrontRight(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviewsFrontRight", file);
    return true;
  }

  @Action
  async addOverviewRearRight(file: File) {
    try {
      const imageResult = await uploadReportImage(this.client, file, ReportImageType.overview, this.partnerName);
      this.context.commit("addToOverviewsRearRight", imageResult);
    } catch (error) {
      this.context.commit("updateUploadErrorMessage", error);
    }
  }

  @Action
  async removeOverviewRearRight(file: File): Promise<boolean> {
    this.context.commit("removeFromOverviewsRearRight", file);
    return true;
  }

  @Mutation
  updateUploadErrorMessage(message: string) {
    this.uploadErrorMessage = message;
  }

  @Mutation
  updateRegistration(file: IImageUploaded[]) {
    this.registrations = file;
  }

  @Mutation
  addToRegistrations(file: IImageUploaded) {
    this.registrations.push(file);
  }

  @Mutation
  removeFromRegistrations(file: File) {
    const filtered = this.registrations.filter(x => x.file.name !== file.name);
    this.registrations = filtered;
  }

  @Mutation
  updateCockpits(file: IImageUploaded[]) {
    this.cockpits = file;
  }

  @Mutation
  addToCockpits(file: IImageUploaded) {
    this.cockpits.push(file);
  }

  @Mutation
  removeFromCockpits(file: File) {
    const filtered = this.cockpits.filter(x => x.file.name !== file.name);
    this.cockpits = filtered;
  }

  @Mutation
  updateDamages(file: IImageUploaded[]) {
    this.damages = file;
  }

  @Mutation
  addToDamages(file: IImageUploaded) {
    this.damages.push(file);
  }

  @Mutation
  removeFromDamages(file: File) {
    const filtered = this.damages.filter(x => x.file.name !== file.name);
    this.damages = filtered;
  }

  @Mutation
  updateDamagesDetail(file: IImageUploaded[]) {
    this.damagesDetail = file;
  }

  @Mutation
  addToDamagesDetail(file: IImageUploaded) {
    this.damagesDetail.push(file);
  }

  @Mutation
  removeFromDamagesDetail(file: File) {
    const filtered = this.damagesDetail.filter(x => x.file.name !== file.name);
    this.damagesDetail = filtered;
  }

  @Mutation
  updateOverviews(file: IImageUploaded[]) {
    this.overviews = file;
  }

  @Mutation
  addToOverviews(file: IImageUploaded) {
    this.overviews.push(file);
  }

  @Mutation
  removeFromOverviews(file: File) {
    const filtered = this.overviews.filter(x => x.file.name !== file.name);
    this.overviews = filtered;
  }

  @Mutation
  addToOverviewsFrontLeft(file: IImageUploaded) {
    this.overviewsFrontLeft.push(file);
  }

  @Mutation
  removeFromOverviewsFrontLeft(file: File) {
    const filtered = this.overviewsFrontLeft.filter(x => x.file.name !== file.name);
    this.overviewsFrontLeft = filtered;
  }

  @Mutation
  addToOverviewsRearLeft(file: IImageUploaded) {
    this.overviewsRearLeft.push(file);
  }

  @Mutation
  removeFromOverviewsRearLeft(file: File) {
    const filtered = this.overviewsRearLeft.filter(x => x.file.name !== file.name);
    this.overviewsRearLeft = filtered;
  }

  @Mutation
  updateOverviewsFrontRight(file: IImageUploaded[]) {
    this.overviewsFrontRight = file;
  }

  @Mutation
  addToOverviewsFrontRight(file: IImageUploaded) {
    this.overviewsFrontRight.push(file);
  }

  @Mutation
  removeFromOverviewsFrontRight(file: File) {
    const filtered = this.overviewsFrontRight.filter(x => x.file.name !== file.name);
    this.overviewsFrontRight = filtered;
  }

  @Mutation
  addToOverviewsRearRight(file: IImageUploaded) {
    this.overviewsRearRight.push(file);
  }

  @Mutation
  removeFromOverviewsRearRight(file: File) {
    const filtered = this.overviewsRearRight.filter(x => x.file.name !== file.name);
    this.overviewsRearRight = filtered;
  }

  @Action
  async connect() {
    this.client = await useReportServiceClient();
  }

  @Action
  async submit() {
    if (!this.client) {
      ErrorLogModule.addErrorLog({
        name: "Sende Fehler",
        message: "Keine Verbindug erstellt, bitte Webseite neu laden"
      });
      throw "Error";
    }

    if (!this.inCreationReport.isPrivacyChecked) {
      Vue.$toast.warning("Bitte Datenschutzerklärung bestätigen.");
    }

    if (!this.inCreationReport) {
      Vue.$toast.warning("Report ist nicht vollständig ausgefüllt.");
      return;
    }

    this.inCreationReport.registrations = this.registrations;
    this.inCreationReport.cockpits = this.cockpits;
    this.inCreationReport.damages = this.damages;
    this.inCreationReport.damagesDetail = this.damagesDetail;

    this.inCreationReport.overviews = this.overviews;
    this.inCreationReport.overviews = this.inCreationReport.overviews.concat(this.overviewsFrontLeft);
    this.inCreationReport.overviews = this.inCreationReport.overviews.concat(this.overviewsFrontRight);
    this.inCreationReport.overviews = this.inCreationReport.overviews.concat(this.overviewsRearLeft);
    this.inCreationReport.overviews = this.inCreationReport.overviews.concat(this.overviewsRearRight);

    try {
      const createdReport = await this.client?.create(
        this.inCreationReport,
        this.user,
        this.address,
        this.contact,
        this.numberplate,
        this.partnerName
      );
      Vue.$log.info(createdReport);
      this.reset();
    } catch (error) {
      Vue.$log.error(error);

      throw error;
    }
  }

  @Action
  reset() {
    this.setStep(1);
    // Report
    this.context.commit("updateInCreationReport", this.EMPTY_REPORT);

    // User
    this.context.commit("updateUser", this.EMPTY_USER);
    this.context.commit("updateAddress", this.EMPTY_ADDRESS);
    this.context.commit("updateContact", this.EMPTY_CONTACT);

    // Images
    this.context.commit("updateRegistrations", []);
    this.context.commit("updateCockpits", []);
    this.context.commit("updateDamages", []);
    this.context.commit("updateDamagesDetail", []);
    this.context.commit("updateOverviews", []);

    // Others
    this.setNumberplate("");
    this.setDatePreference(new Date().toISOString());
  }

  @Action
  updateReport(report: Partial<IReport>) {
    const reportMap = { ...this.inCreationReport, ...report };
    this.context.commit("updateInCreationReport", reportMap);
  }

  @Mutation
  updateInCreationReport(report: IReport) {
    this.inCreationReport = report;
  }

  @Action
  setPartnerName(partnerName: string) {
    this.context.commit("updatePartnerName", partnerName);
  }

  @Action
  async getPartnerByName(partnerName: string) {
    try {
      const response = await partnerService.getPartnerByName(partnerName);
      const partnerData = response.data as PartnerEntity;
      this.context.commit("setPartner", partnerData);
    } catch (e) {
      Vue.$toast.error(`Partner ${partnerName} nicht gefunden.`);
      throw e;
    }
  }

  @Mutation
  setPartner(partner: PartnerEntity) {
    this.partner = partner;
    this.loading = false;
  }

  @Mutation
  updatePartnerName(partnerName: string) {
    this.partnerName = partnerName;
  }

  @Action
  setLoading(loading: boolean) {
    this.context.commit("updateLoading", loading);
  }

  @Action
  setPartnerDetails(partner: PartnerEntity) {
    this.context.commit("updatePartnerDetails", partner);
  }

  @Mutation
  updatePartnerDetails(partner: PartnerEntity) {
    this.partner = partner;
  }

  @Mutation
  updateLoading(loading: boolean) {
    this.loading = loading;
  }

  @Action
  setDrawer(val: any) {
    this.context.commit("updateDrawer", val);
  }

  @Mutation
  updateDrawer(val: any) {
    this.drawer = val;
  }

  @Action
  setMaxStep(n: number) {
    this.context.commit("updateMaxStep", n);
  }

  @Mutation
  updateMaxStep(maxStep: number) {
    this.MAX_STEP = maxStep;
  }

  @Action
  setStep(n: number) {
    if (n > this.MAX_STEP) {
      n = this.MAX_STEP;
    }

    if (n < this.MIN_STEP) {
      n = this.MIN_STEP;
    }

    this.context.commit("updateCurrentStep", n);
  }

  @Mutation
  updateCurrentStep(n: number) {
    this.currentStep = n;
  }
}

async function uploadReportImage(
  client: ReportServiceClient | undefined,
  file: File,
  type: ReportImageType,
  partnerName: string
): Promise<IImageUploaded> {
  if (!partnerName) {
    throw Error("Partner not set.");
  }

  if (!file) {
    throw Error("File to upload missing.");
  }

  if (!type) {
    throw Error("Image Type missing.");
  }

  if (!client) {
    Vue.$toast.error("Verbindung unterbrochen.");
    throw Error("Verbindung unterbrochen.");
  }

  let imageResult: IImageUploaded;

  try {
    imageResult = await client.addImage(file, type, partnerName);
    return imageResult;
  } catch (error) {
    Vue.$log.error(error);
    const e = error as any;
    if (e.error) {
      if (e.error.statusCode == 400) {
        Vue.$toast.error("Fehler: Der Service ist zur Zeit nicht zur Verfügung.");
      }

      if (e.error.statusCode == 500) {
        Vue.$toast.error("Fehler: Der Service ist zur Zeit nicht zur Verfügung.");
      }
    }
    throw error;
  }
}

export const ReportModule = getModule(ReportStore);
