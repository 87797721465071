import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import ReportHome from "@/views/Report.vue";
import ReportSummary from "@/views/report/ReportSummary.vue";
import ReportDescription from "@/views/report/ReportFormDescription.vue";
import ReportRegistrationImage from "@/views/report/ReportImageRegistration.vue";
import ReportCockpitImage from "@/views/report/ReportImageCockpit.vue";
import ReportDamageImage from "@/views/report/ReportImageDamage.vue";
import ReportDamageDetailImage from "@/views/report/ReportImageDamageDetail.vue";
import ReportOverviewImage from "@/views/report/ReportImageOverview.vue";
import ReportPersonalData from "@/views/report/ReportFormPersonalData.vue";
import ReportDatePicker from "@/views/report/ReportFormDatePicker.vue";
import ReportSuccess from "@/views/report/ReportSuccess.vue";
import PartnerContactError from "@/views/PartnerContactError.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    component: ReportHome
  },
  {
    path: "/1",
    component: ReportHome
  },
  {
    path: "/2",
    component: ReportRegistrationImage
  },
  {
    path: "/3",
    component: () => import(/* webpackChunkName: "svg" */ "@/views/report/ReportLocation.vue")
  },
  {
    path: "/4",
    component: ReportOverviewImage
  },
  {
    path: "/5",
    component: ReportDamageImage
  },
  {
    path: "/6",
    component: ReportDamageDetailImage
  },
  {
    path: "/7",
    component: ReportCockpitImage
  },
  {
    path: "/8",
    component: ReportDescription
  },
  {
    path: "/9",
    component: ReportPersonalData
  },
  {
    path: "/10",
    component: ReportDatePicker
  },
  {
    path: "/11",
    component: ReportSummary
  },
  {
    path: "/success",
    component: ReportSuccess,
    name: "ReportSuccess"
  },
  {
    path: "/error",
    name: "PartnerContactError",
    component: PartnerContactError
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "*",
    component: ReportHome
  }
];

const router = new VueRouter({
  routes,
  // mode: "history",
  base: "/report/",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
