































import { Component } from "vue-property-decorator";

import { ReportModule } from "@/store/modules/report.store";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import ImageUploadButton from "@/components/files/ImageUploadButton.vue";
import FileUpload from "@/components/files/FileUpload.vue";
import FileExample from "@/components/files/FileExample.vue";

import { AssetEnum } from "@/lib/AssetRepository";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";

import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IImageUploaded } from "@/models/Image/IImageUploaded";

@Component({
  components: {
    LayoutReportStep,
    ImageUploadButton,
    FileExample,
    FileUpload
  }
})
export default class ReportImageDamageDetail extends ReportImageSubmission
  implements IReportStep, IViewImageUploadable {
  public example = AssetEnum.damageDetail;

  header: IReportHeaderData = {
    title: "Detailaufnahmen",
    description: "Bitte fertigen Sie nun Fotos aus nächster Nähe vom Schaden an.",
    image: AssetEnum.damageDetailExample,
    debug: this.files
  };

  instruction: IReportInstructionData = {
    title: this.header.title,
    text:
      "Bitte fertigen Sie detailliert Nahaufnahmen an. In diesem Schritt geht es darum im Detail das Ausmaß des Schadens zu dokumentieren.",
    pictures: [AssetEnum.damageDetailInstruction],
    example: AssetEnum.damageDetailExample,
    display: true
  };

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    await ReportModule.addDamageDetail(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    await ReportModule.removeDamageDetail(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.damagesDetail;
  }
}
