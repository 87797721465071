







































































































import { PartnerEntity } from "@/models/partnerEntity";

import { Component, Prop, Vue } from "vue-property-decorator";

@Component({})
export default class PartnerFooter extends Vue {
  @Prop()
  partnerProp?: PartnerEntity;

  @Prop()
  color?: string;

  get address() {
    return this.partnerProp?.address;
  }

  get settings() {
    return this.partnerProp?.settings;
  }
}
