













import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TopToolbarBack extends Vue {
  @Prop({ default: "" })
  title!: string;

  goBack() {
    this.$router.go(-1);
  }
}
