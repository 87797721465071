import { Api } from "../v1/mrfiktiv.api";
import { ConfigModule } from "@/store/modules/config";

export class AbstractServiceClient {
  protected api: Api<unknown>;

  constructor(public baseUrl: string, public access_token: string) {
    // FIXME: this is not working locally because of cors - we need to figure this out.
    // const headers = {
    //   "Content-Type": "application/json",
    //   Authorization: this.access_token
    // };
    // this.api = new Api({ baseUrl: this.baseUrl, baseApiParams: { headers: headers } });
    this.api = new Api({ baseUrl: this.baseUrl });
  }
}

export class BaseServiceClient extends AbstractServiceClient {
  constructor(baseUrl = ConfigModule.mrfiktivBaseUrl, access_token = ConfigModule.mrfiktivAccessToken) {
    super(baseUrl, access_token);
  }
}
