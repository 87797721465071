import axios from "axios";
import { ConfigModule } from "@/store/modules/config";

export class Api {
  instance() {
    const client = axios.create({
      baseURL: ConfigModule.mrfiktivBaseUrl,
      timeout: 20000
    });
    // Add a request interceptor
    client.interceptors.request.use(function(config) {
      const token = `Bearer ${ConfigModule.mrfiktivAccessToken}`;

      if (token) {
        config.headers.Authorization = token;
      }
      return config;
    });

    return client;
  }
}
