/**
 * Checks wether a given file is an image.
 * @param file to be checked if its an image.
 * @returns if given file is an image.
 */
export function isImage(file: File): boolean {
  return file.type.indexOf("image/") >= 0;
}

/**
 * Returns a given file as an URL string.
 * @param file input file converted to a URL string
 * @returns file as URL string
 */
export function fileAsUrl(file: File): string {
  return URL.createObjectURL(file);
}
