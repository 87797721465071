






























import { Component } from "vue-property-decorator";

import { ReportModule } from "@/store/modules/report.store";
import LayoutReportStep from "@/layouts/LayoutReportStep.vue";
import ImageUploadButton from "@/components/files/ImageUploadButton.vue";
import FileUpload from "@/components/files/FileUpload.vue";
import FileExample from "@/components/files/FileExample.vue";

import { AssetEnum } from "@/lib/AssetRepository";
import { IReportStep } from "@/lib/interfaces/Report/IViewReportStep";

import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IReportHeaderData } from "@/lib/interfaces/Report/IReportHeaderData";
import { IReportInstructionData } from "@/lib/interfaces/Report/IReportInstructionData";
import { IImageUploaded } from "@/models/Image/IImageUploaded";

@Component({
  components: {
    LayoutReportStep,
    ImageUploadButton,
    FileExample,
    FileUpload
  }
})
export default class ReportRegistrationImage extends ReportImageSubmission
  implements IReportStep, IViewImageUploadable {
  public maxFiles = 1;

  public example = AssetEnum.registration;

  public header: IReportHeaderData = {
    title: "Foto vom Fahrzeugschein",
    description:
      "Bitte laden Sie ein Foto von der Vorderseite des <b>aufgeklappten</b> Fahrzeugscheins hoch, damit wir Ihr Fahrzeug identifizieren können (siehe Beispiel). ",
    image: AssetEnum.registrationExample,
    debug: this.files
  };

  public instruction: IReportInstructionData = {
    title: this.header.title,
    text: this.header.description,
    pictures: [AssetEnum.registrationCorrectInstructions, AssetEnum.registrationIncorrectInstructions],
    example: AssetEnum.registrationExample,
    display: true
  };

  /**
   * @inheritdoc
   */
  async handleUpload(file: File): Promise<boolean> {
    await ReportModule.addRegistration(file);
    return true;
  }

  /**
   * @inheritdoc
   */
  async handleDelete(file: File): Promise<boolean> {
    return await ReportModule.removeRegistration(file);
  }

  /**
   * @inheritdoc
   */
  get files(): IImageUploaded[] {
    return ReportModule.registrations.slice().reverse();
  }
}
