







import { Component, Prop, Vue } from "vue-property-decorator";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import { latLng, Icon, divIcon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { GeoEntity } from "@/models/geoEntity";

// this is important for loading the icons
// https://vue2-leaflet.netlify.app/quickstart/#installation
type D = Icon.Default & {
  _getIconUrl?: string;
};

delete (Icon.Default.prototype as D)._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

@Component({ components: { LMap, LMarker, LIcon, LTileLayer } })
export default class PartnerMap extends Vue {
  url = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  attribution = '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

  @Prop({ default: 13 })
  zoom?: number;

  @Prop()
  coordinates!: GeoEntity;

  @Prop()
  colour!: string;

  // this is important for styling the marker according to the partner color
  markerHtmlStyles = `
  background-color: ${this.colour};
  width: 2rem;
  height: 2rem;
  display: block;
  left: -1.5rem;
  top: -1.5rem;
  position: relative;
  border-radius: 2rem 2rem 0;
  transform: rotate(45deg);
  border: 1px solid #FFFFFF`;

  icon = divIcon({
    className: "my-custom-pin",
    html: `<span style="${this.markerHtmlStyles}" />`
  });

  get marker() {
    return latLng(this.coordinates.lat, this.coordinates.lng);
  }
}
