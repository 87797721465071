
import { Vue, Component } from "vue-property-decorator";

import { ReportModule } from "@/store/modules/report.store";
import { getDefaultPartnerColor } from "@/lib/getDefaultPartnerColor";
import { isImage } from "@/lib/files/ImageHelper";
import { IViewImageUploadable } from "@/lib/interfaces/Report/IViewImageUploadable";
import { IImageUploaded } from "@/models/Image/IImageUploaded";

@Component({})
export default class ReportImageSubmission extends Vue implements IViewImageUploadable {
  /**
   * Min Files indiciate the minium required files to be uploaded.
   * Validated via the `isDone()` method.
   * default = 1 (require one image atleast by default).
   */
  public minFiles = 1;

  /**
   * Max Files indicate wether a given maximum of files for the component is set.
   * 0 = no limit
   */
  public maxFiles = 0;

  /**
   * Default text of the `ImageUploadButton.vue` depending on single or multi file upload.
   */
  public defaultAltTextByMaxFiles = this.maxFiles === 1 ? "Foto erneut aufnehmen?" : "Weiteres Foto aufnehmen?";

  /**
   * Default value to set the header width.
   */
  public md = 6;

  /**
   * Indiates a current upload is in progress
   */
  protected isLoading = false;

  /**
   * Indicates wether a file is uploaded.
   */
  protected isUploaded = false;

  /**
   * Inidicates wether the given ReportStep is complete.
   * @returns {boolean} Image Upload is done
   */
  get isDone(): boolean {
    if (!this.files) return false;

    const isDone: boolean = this.files.length >= this.minFiles;
    return isDone;
  }

  /**
   * Callback function for the change event on image upload button.
   * Via `ImageUploadButton.vue`
   *
   * @param event any - The event that contains the files to uploaded.
   */
  private onFileInput(event: any) {
    const file = event.target.files[0];
    this.handleFileAsImage(file);
  }

  /**
   * Internal function to abstract the event and handling of file type.
   * Requires an image to be passed, otherwise displays error.
   *
   * @params {File} file - file to be processed
   */
  private handleFileAsImage(file: File) {
    if (isImage(file)) {
      this.uploaded(file);
    } else {
      this.$log.error("Not an image found");
      this.$toast.error("Bitte ausschließlich Bilder hochladen.");
    }
  }

  /**
   * Handles the upload of a given file type.
   * Needs to be overwritten by each view to implement a given file type upload.
   * @param file - file to upload
   */
  public async handleUpload(file: File): Promise<boolean> {
    throw Error(`handleUpload not implemented ${file.name}`);
  }

  /**
   * Hanldes the deletion of uploaded file.
   * Needs to be overwritten by each view to implement a given file type deletion.
   * @param file - file to be removed
   */
  public async handleDelete(file: File): Promise<boolean> {
    throw Error(`handleDelete not implemented ${file.name}`);
  }

  /**
   * Returns the given files of the store by file type.
   * Needs to be overwritten by each view to implement a given file type.
   */
  get files(): IImageUploaded[] {
    throw Error("files not implemented");
  }

  /**
   * Computed property for the color.
   */
  get color() {
    return getDefaultPartnerColor();
  }

  /**
   * Handles the event uploaded.
   * Sets the private loading and uploaded flags.
   * Calls the parent `handleUpload()` and `handleDeleted()` methods.
   * If `maxFiles` is set, the `handleDeleted()` method is called,
   * using the last element of the files so that the oldest element is overwritten
   * and the component only stores the max files required.
   *
   * @param event - event that contains the file to be uploaded
   */
  private async uploaded(e: any) {
    if (!e) return;

    this.isUploaded = false;
    this.isLoading = true;

    const file = e;

    if (this.maxFiles && this.files.length >= this.maxFiles) {
      await this.handleDelete(this.files[this.files.length - 1].file);
    }

    try {
      if (file) {
        const success = await this.handleUpload(file);
        this.isUploaded = true;
        if (!success) {
          this.$toast.error(ReportModule.uploadErrorMessage);
        }
      }
    } catch (error) {
      this.$toast.error(ReportModule.uploadErrorMessage);
    }

    this.isLoading = false;
  }

  /**
   * Handles the event deletion.
   * Sets the private loading and uploaded flags.
   * Calls the parent `handleUpload()` and `handleDeleted()` methods.
   *
   * @param event - event that contains the file to be uploaded
   */
  private async deleted(e: any) {
    if (!e) return;

    this.isUploaded = false;
    this.isLoading = true;

    const file = e;

    try {
      if (file) {
        const success = await this.handleDelete(file);
        if (!success) {
          this.$toast.error(ReportModule.deleteErrorMessage);
        }
      }
    } catch (error) {
      this.$toast.error(ReportModule.deleteErrorMessage);
    }

    this.isLoading = false;
  }
}
