






import { Prop, Component, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class TopToolbar extends Vue {
  @Prop({ default: "" })
  src!: string;
}
