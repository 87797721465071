import Vuetify from "@/plugins/VuetifyPlugin";
import store from "@/store";
import { SessionModule } from "@/store/modules/session";
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";

@Module({
  dynamic: true,
  namespaced: true,
  name: "config",
  store
})
export class Config extends VuexModule {
  debug = false;
  darkMode = false;
  token = "";
  fahrzeugscheinToken = "";
  LOCAL_STORAGE_ACCESS_TOKEN = "access_token";

  isLoginSheetVisible = false;
  isFahrzeugscheinTokenValid = false;

  BASE_URL_PORT = 3000;
  fahrzeugscheinBaseUrl = "https://dev.api.mmmint.ai/fahrzeugschein/v1";

  privacyPolicyLink = "https://www.mmmint.ai/datenschutz/";

  throttleTimerInMs = 2000;

  developmentStatusAcknowledgement = false;

  // Backend URL
  developmentMode = true;
  developmentUrl = "https://dev.api.mrfiktiv.com/v1";
  productionUrl = "https://api.mrfiktiv.com/v1";
  localUrl = `http://localhost:${this.BASE_URL_PORT}`;

  mrfiktivBaseUrl =
    process.env.NODE_ENV === "production"
      ? this.productionUrl
      : this.developmentMode
      ? this.developmentUrl
      : this.localUrl;

  mrfiktivAccessToken = localStorage.getItem(this.LOCAL_STORAGE_ACCESS_TOKEN) || "";

  localLoginUlr =
    "https://dev-mrfiktiv.auth.eu-central-1.amazoncognito.com/login?client_id=6p2pq3ae23s1s5vv483un0o0cn&response_type=token&scope=email+openid&redirect_uri=http://localhost:8080/";
  developmentLoginUrl =
    "https://dev-mrfiktiv.auth.eu-central-1.amazoncognito.com/login?client_id=6g006bg2mbgp04cr9pr805r5a1&response_type=token&scope=email+openid&redirect_uri=https://dev.app.mrfiktiv.com/";
  productionLoginUrl =
    "https://mrfiktiv.auth.eu-central-1.amazoncognito.com/login?response_type=token&client_id=47q5nfpd31i3md3cegc5qpfo74&redirect_uri=https%3A%2F%2Fapp.mrfiktiv.com%2F";

  loginUrl = process.env.NODE_ENV === "production" ? this.productionLoginUrl : this.developmentLoginUrl;

  color = {
    primary: "#ff5661",
    secondary: "#BDBDBD",
    status: {
      finished: "green darken-2",
      processing: "#ff5661",
      pending: "#FFFFFF"
    }
  };

  @MutationAction
  async setToken(token: string) {
    return {
      token
    };
  }

  @MutationAction
  async setDebug(debug: boolean) {
    return {
      debug
    };
  }

  @Action
  async setDevelopmentMode(developmentMode: boolean) {
    this.context.commit("updateDevelopmentMode", developmentMode);

    const baseUrl =
      process.env.NODE_ENV === "production"
        ? developmentMode
          ? this.developmentUrl
          : this.productionUrl
        : this.localUrl;

    this.context.commit("setMrfiktivBaseUrl", baseUrl);
  }

  @Mutation
  async setMrfiktivAccessToken(mrfiktivAccessToken: string) {
    this.mrfiktivAccessToken = mrfiktivAccessToken;
  }

  @Mutation
  async setMrfiktivBaseUrl(mrfiktivBaseUrl: string) {
    this.mrfiktivBaseUrl = mrfiktivBaseUrl;
  }

  @Mutation
  updateDevelopmentMode(val: boolean) {
    this.developmentMode = val;
  }

  @Mutation
  updateDevelopmentStatusAcknowledgement(val: boolean) {
    this.developmentStatusAcknowledgement = val;
  }

  @Action
  updateMrfiktivAccessToken(val: string) {
    localStorage.setItem(this.LOCAL_STORAGE_ACCESS_TOKEN, val);
    this.context.commit("setMrfiktivAccessToken", val);
  }

  @Action
  logoutMrfiktiv() {
    localStorage.removeItem(this.LOCAL_STORAGE_ACCESS_TOKEN);
    this.context.commit("setMrfiktivAccessToken", "");
  }

  @Action
  async setDevelopmentStatusAcknowledgement(val: boolean) {
    this.context.commit("updateDevelopmentStatusAcknowledgement", val);
  }

  @Action
  async setDarkMode(val: boolean) {
    this.context.commit("updateDarkModeSetting", val);
  }

  @Mutation
  updateDarkModeSetting(val: boolean) {
    Vuetify.framework.theme.dark = val;
    this.darkMode = val;
  }

  @Action
  async setFahrzeugscheinToken(val: string) {
    this.context.commit("updateFahrzeugscheinToken", val);
    await SessionModule.fetchSessions();
  }

  @Action
  async setFahrzeugscheinTokenValid(val: boolean) {
    this.context.commit("updateFahrzeugscheinTokenValid", val);
  }

  @Action
  async setCachedFahrzeugscheine(val: boolean) {
    this.context.commit("updateFahrzeugscheinTokenValid", val);
  }

  @Mutation
  updateFahrzeugscheinToken(val: string) {
    this.fahrzeugscheinToken = val;
  }

  @Mutation
  updateFahrzeugscheinTokenValid(val: boolean) {
    this.isFahrzeugscheinTokenValid = val;
  }

  @Action
  makeLoginSheetVisible() {
    this.context.commit("setLoginSheetVisible", true);
  }

  @Action
  hideLoginSheetVisible() {
    this.context.commit("setLoginSheetVisible", false);
  }

  @Mutation
  setLoginSheetVisible(val: boolean) {
    this.isLoginSheetVisible = val;
  }
}

export const ConfigModule = getModule(Config);
