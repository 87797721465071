

















import { Component, Vue } from "vue-property-decorator";

@Component
export default class LogoutList extends Vue {
  debug = this.$store.state.config.debug;

  // Log the user out
  logout() {
    this.$auth.logout();
  }
}
