import { render, staticRenderFns } from "./TopToolbarLogo.vue?vue&type=template&id=251922f4&"
import script from "./TopToolbarLogo.vue?vue&type=script&lang=ts&"
export * from "./TopToolbarLogo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VAppBar,VImg})
