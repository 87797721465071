export function emailRule(message = "Bitte eine valide E-Mail Adresse angeben."): (value: any) => boolean | string {
  const regex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
  return value => regex.test(value) || message;
}

/*
  Match a single character present in the list below [+]
  ? matches the previous token between zero and one times, as many times as possible, giving back as needed (greedy)
  + matches the character + literally (case sensitive)
  Match a single character present in the list below [0-9\s]
  * matches the previous token between zero and unlimited times, as many times as possible, giving back as needed (greedy)
  0-9 matches a single character in the range between 0 (index 48) and 9 (index 57) (case sensitive)
  \s matches any whitespace character (equivalent to [\r\n\t\f\v ])
*/
export function phoneRule(
  message = "Bitte eine valide Telefonnummer angeben, z.B. +49 123 456 oder 123 456."
): (value: any) => boolean | string {
  const regex = RegExp(/^[+]?[0-9\s]*$/);
  return value => regex.test(value) || message;
}
