



















import { UserEntity } from "@/models/userEntity";
import { UserModule } from "@/store/modules/user";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ProfileShort extends Vue {
  debug = this.$store.state.config.debug;

  get user(): UserEntity {
    return UserModule.user;
  }
  // // Log the user out
  // logout() {
  //   this.$auth.logout({
  //     returnTo: window.location.origin
  //   });
  // }

  // login() {
  //   this.$auth.loginWithRedirect({ appState: { targetUrl: "/" } });
  // }
  goToProfile() {
    this.$router.push({ name: "Profile" });
  }
}
