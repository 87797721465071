import axios from "axios";
import { ConfigModule } from "@/store/modules/config";
import AccessTokenMissing from "./AccessTokenMissing";

export class Api {
  instance() {
    return axios.create({
      baseURL: ConfigModule.fahrzeugscheinBaseUrl,
      timeout: 20000, // indicates, 1000ms ie. 1 second
      headers: {
        "Content-type": "application/json"
      }
    });
  }

  public static getToken(): string {
    if (ConfigModule.fahrzeugscheinToken) {
      return "?access_token=" + ConfigModule.fahrzeugscheinToken;
    }
    throw new AccessTokenMissing();
  }
}
